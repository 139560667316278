import { get } from '@utils/fetch';

export interface ChefRandom {
  total: number;
  data: ChefsData[];
}

export interface ChefsData {
  id: number;
  name: string;
  imageProfile: {
    id: number;
    imageUrl: string;
    thumbnailUrl: string;
  };
  titleLocation: string;
  typeOfLocation: string;
  base: {
    id: number;
    name: string;
    isActive: boolean;
    countryId: number;
  };
  about: string;
  typeOfCuisines: TypeOfCuisine[];
}

interface TypeOfCuisine {
  id: number;
  name: string;
  isActive: boolean;
}

export async function get_chef_random() {
  return await get<ChefRandom[]>(`${process.env.NEXT_PUBLIC_API_URL}/chef/random`);
}
