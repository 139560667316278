import { ReactNode } from 'react';
import { IconifyIcon } from '@iconify/react';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import { Box, BoxProps, Stack, StackProps, IconButtonProps } from '@mui/material';
//
import Iconify from '../Iconify';
import { IconButtonAnimate } from '../animate';
import { CustomArrowProps } from 'react-slick';

// ----------------------------------------------------------------------

const BUTTON_SIZE = 40;

interface ArrowStyleProps extends IconButtonProps {
  filled: boolean;
}

const ArrowStyle = styled(IconButtonAnimate, {
  shouldForwardProp: (prop) => prop !== 'filled',
})<ArrowStyleProps>(({ filled, theme }) => ({
  width: BUTTON_SIZE,
  height: BUTTON_SIZE,
  cursor: 'pointer',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:hover': {
    color: theme.palette.text.primary,
  },
  ...(filled && {
    opacity: 1,
    borderRadius: Number(theme.shape.borderRadius) * 1.5,
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[900],
    '&:hover': {
      opacity: 1,
      color: theme.palette.common.white,
      backgroundColor: theme.palette.grey[900],
    },
  }),
}));

// ----------------------------------------------------------------------

type IProps = BoxProps & StackProps;

interface Props extends IProps {
  filled?: boolean;
  children?: ReactNode;
  customIcon?: IconifyIcon | string;
  onNext?: VoidFunction;
  onPrevious?: VoidFunction;
  showLeftArrow?: boolean;
  showRightArrow?: boolean;
}

export default function CarouselArrows({
  filled = false,
  customIcon, // Set icon right
  onNext,
  onPrevious,
  children,
  showLeftArrow,
  showRightArrow,

  ...other
}: Props) {
  const theme = useTheme();
  const isRTL = theme.direction === 'rtl';

  const style = {
    position: 'absolute',
    mt: -2.5,
    top: '50%',
    zIndex: 9,
  } as const;

  if (children) {
    return (
      <Box {...other}>
        {showLeftArrow && (
          <Box className="arrow left" sx={{ ...style, left: 0 }}>
            <ArrowStyle filled={filled} onClick={onPrevious}>
              {leftIcon(customIcon, isRTL)}
            </ArrowStyle>
          </Box>
        )}

        {children}

        {showRightArrow && (
          <Box className="arrow right" sx={{ ...style, right: 0 }}>
            <ArrowStyle filled={filled} onClick={onNext}>
              {rightIcon(customIcon, isRTL)}
            </ArrowStyle>
          </Box>
        )}
      </Box>
    );
  }

  return (
    <Stack direction="row" spacing={1} {...other}>
      <ArrowStyle className="arrow left" filled={filled} onClick={onPrevious}>
        {leftIcon(customIcon, isRTL)}
      </ArrowStyle>
      <ArrowStyle className="arrow right" filled={filled} onClick={onNext}>
        {rightIcon(customIcon, isRTL)}
      </ArrowStyle>
    </Stack>
  );
}

// ----------------------------------------------------------------------

const leftIcon = (customIcon?: IconifyIcon | string, isRTL?: boolean) => (
  <Iconify
    icon={customIcon ? customIcon : 'eva:arrow-right-fill'}
    sx={{
      width: 20,
      height: 20,
      transform: ' scaleX(-1)',
      ...(isRTL && { transform: ' scaleX(1)' }),
    }}
  />
);

const rightIcon = (customIcon?: IconifyIcon | string, isRTL?: boolean) => (
  <Iconify
    icon={customIcon ? customIcon : 'eva:arrow-right-fill'}
    sx={{
      width: 20,
      height: 20,
      ...(isRTL && { transform: ' scaleX(-1)' }),
    }}
  />
);

const NextArrow = (props: CustomArrowProps) => {
  const { slideCount, currentSlide, onClick, className } = props;

  const style = {
    position: 'absolute',
    mt: -2.5,
    top: '47%',
    mr: 2,
    zIndex: 9,
  } as const;

  return slideCount == 0 || (slideCount != undefined && currentSlide == slideCount - 1) ? (
    <></>
  ) : (
    <Box className="arrow right" sx={{ ...style, right: 0 }}>
      <ArrowStyle filled onClick={props.onClick}>
        {rightIcon()}
      </ArrowStyle>
    </Box>
  );
};

const PrevArrow = (props: CustomArrowProps) => {
  const { slideCount, currentSlide, onClick, className } = props;

  const style = {
    position: 'absolute',
    mt: -2.5,
    top: '47%',
    ml: 2,
    zIndex: 9,
  } as const;

  return currentSlide == 0 || undefined ? (
    <></>
  ) : (
    <Box className="arrow left" sx={{ ...style, left: 0 }}>
      <ArrowStyle filled onClick={props.onClick}>
        {leftIcon()}
      </ArrowStyle>
    </Box>
  );
};

const NextArrowOpenTableRecommend = (props: CustomArrowProps) => {
  const { slideCount, currentSlide, onClick, className } = props;

  const style = {
    position: 'absolute',
    mt: 0,
    top: '56%',
    mr: 2,
    zIndex: 9,
  } as const;

  return slideCount == 0 || (slideCount != undefined && currentSlide == slideCount - 1) ? (
    <></>
  ) : (
    <Box className="arrow right" sx={{ ...style, right: 0 }}>
      <ArrowStyle filled onClick={props.onClick}>
        {rightIcon()}
      </ArrowStyle>
    </Box>
  );
};

const PrevArrowOpenTableRecommend = (props: CustomArrowProps) => {
  const { slideCount, currentSlide, onClick, style, className } = props;

  const styles = {
    position: 'absolute',
    mt: 0,
    top: '56%',
    ml: 2,
    zIndex: 9,
  } as const;

  return currentSlide == 0 || undefined ? (
    <></>
  ) : (
    <Box className="arrow left" sx={{ ...styles, left: 0 }}>
      <ArrowStyle filled onClick={props.onClick}>
        {leftIcon()}
      </ArrowStyle>
    </Box>
  );
};

export { NextArrow, PrevArrow, NextArrowOpenTableRecommend, PrevArrowOpenTableRecommend };
