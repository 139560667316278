import Image from '@components/Image';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import Slider from 'react-slick';
import { CarouselDots } from '@components/carousel';
import { resize_image } from '@utils/image';
import { getDestinations } from '@services/destination';
import type { Destination } from '@services/destination';
import { NextArrow, PrevArrow } from '@components/carousel/CarouselArrows';

const RootStyle = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .slick-list': {
    boxShadow: theme.customShadows.z16,
    borderRadius: Number(theme.shape.borderRadius) * 3,
  },
}));

const DestinationsSection: FunctionComponent = () => {
  const router = useRouter();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const overMd = useMediaQuery(theme.breakpoints.up('md'));
  const overLg = useMediaQuery(theme.breakpoints.up('lg'));
  const [destination, setDestination] = useState<Destination[]>([]);

  const idLocationCards = [
    'DiningDestination_BKK',
    'DiningDestination_HHQ',
    'DiningDestination_CNX',
    'DiningDestination_HDY',
    'DiningDestination_Home',
  ];

  useEffect(() => {
    const fetchDestination = async () => {
      getDestinations().then((res) => {
        setDestination(res);
      });
    };
    fetchDestination();
  }, []);

  const settings = {
    arrows: false,
    autoplay: false,
    infinite: false,
    ...CarouselDots({
      rounded: true,
      sx: { mt: 3 },
    }),
    nextArrow: <NextArrow className="next-arrow-destination" />,
    prevArrow: <PrevArrow className="prev-arrow-destination" />,
  };

  const LocationCardsList = () => (
    <Grid
      container
      flexWrap="nowrap"
      overflow="auto"
      pb={{ xs: 6, md: 10 }}
      spacing={{ xs: 2, md: 3 }}
      sx={{
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Grid item xs={12}>
        <RootStyle>
          <Slider
            {...settings}
            dots={isDesktop}
            arrows={isDesktop}
            className="sliderHidden"
            slidesToShow={overLg ? 4 : overMd ? 3 : isDesktop ? 2 : 1.3}
            slidesToScroll={overLg ? 4 : overMd ? 3 : isDesktop ? 2 : 1}
            infinite={isDesktop}
          >
            {[...destination].map((item, index) => (
              <Box
                id={idLocationCards[index]}
                key={index}
                sx={{
                  width: 500,
                  height: 365,
                  pl: index !== 0 ? (index === 1 ? 0 : 2) : 0,
                  pr: index === 0 ? 2 : 0,
                  mt: 2,
                  backgroundColor: '#EFEBE5',
                  boxShadow: 'none',
                  position: 'relative',
                  cursor: 'pointer',
                }}
                onClick={() => router.push(`/destination/${item.name}`)}
              >
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    borderRadius: 2,
                    position: 'relative',
                  }}
                >
                  <Image
                    key={index}
                    src={resize_image({ url: item.image.imageUrl, height: 365 })}
                    alt={item.name}
                    sx={{
                      borderRadius: 2,
                      width: '100%',
                      height: '100%',
                      opacity: 0.8,
                    }}
                  />
                  <Box
                    sx={{
                      background:
                        'linear-gradient(0deg, rgba(0, 0, 0, 0.7) 21.02%, rgba(217, 217, 217, 0) 110.8%);',
                      width: '100%',
                      height: '35%',
                      boxShadow: 'inset 0px -50px 36px -28px rgba(0, 0, 0, 0.4941)',
                      // mx: 'auto',
                      position: 'absolute',
                      bottom: '0',
                      borderRadius: '0 0 20px 20px',
                    }}
                  />
                  <Typography
                    variant="h3"
                    sx={{
                      position: 'absolute',
                      bottom: '0',
                      left: '0',
                      pb: '12%',
                      color: '#FFFFFF',
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    {item.name}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Slider>
        </RootStyle>
      </Grid>
    </Grid>
  );

  return (
    <Box>
      <Container>
        <Stack direction={isDesktop ? 'row' : 'column'} mb={1} mt={5.625}>
          <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
            Explore Dining
          </Typography>
          <Typography
            fontSize={isDesktop ? 32 : 24}
            fontWeight={700}
            ml={!isDesktop ? 0 : 1}
            color="#F15B40"
          >
            Destinations
          </Typography>
        </Stack>
        <LocationCardsList />
      </Container>
    </Box>
  );
};

export default DestinationsSection;
