import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useState, useRef } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import Stack from '@mui/material/Stack';
import { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import Slider from 'react-slick';
import CarouselArrowIndex from '@components/CarouselArrowIndex';
import Button from '@mui/material/Button';
import { m } from 'framer-motion';
import { varFade } from '@components/animate';
import { CarouselDots } from '@components/carousel';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { resize_image } from '@utils/image';

//facebook chat
import { FacebookProvider, CustomChat } from 'react-facebook';

// Heaven On Earth
// import { useEffect, useCallback } from 'react';
// import { post_join_open_table } from 'src/services/booking';
// import { SelectLocation } from 'src/types/book-seat';
// import { get_access_token } from 'src/services/auth';
// import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
// import { profileState } from '@atoms/profile';
// import { authState, signUpSignInModalState } from '@atoms/auth';
// import VerifyPhoneModal from '@components/book-seat/VerifyPhoneModal';
// import { bookSeatAtom, bookSeatAtomDefaultState } from '@atoms/book-seat';

const RootStyle = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .slick-list': {
    boxShadow: theme.customShadows.z16,
    borderRadius: Number(theme.shape.borderRadius) * 3,
  },
}));

const ImageSlide = ({
  id,
  alt,
  image,
  isLink,
  handleOnClick,
}: {
  id: string;
  alt: string;
  image: string;
  isLink: boolean;
  handleOnClick?: VoidFunction;
}) => {
  return (
    <img
      id={id}
      alt={alt}
      src={image}
      onClick={handleOnClick}
      style={{ cursor: isLink ? 'pointer' : undefined }}
    />
  );
};

const StackTransition = styled(Stack)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
}));

const CardButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'white',
  color: 'black',
  padding: '11px 22px',
  borderRadius: '11.6842px',
  '&:hover': {
    backgroundColor: 'white',
  },
}));

const desktopImage = [
  'https://images.potioneer.com/Landing_Page_Banner/New_Open_Table/Banner-OTB-Desktop_NwPn2mlV8.jpg',
  'https://images.potioneer.com/Landing_Page_Banner/Banner_Download_app/Desktop_Download_app_V-UXoCJbp__J2tOCx2Sq.jpg',
  'https://images.potioneer.com/images-landing/Carosal/Desktop/unsplash_673jcnrm8bM_welcome_0OY_oCn6J.png',
  'https://images.potioneer.com/images-landing/Carosal/Desktop/unsplash_673jcnrm8bM_Course_uNm0XrN-3.png',
  'https://images.potioneer.com/images-landing/Carosal/Desktop/unsplash_673jcnrm8bM_Chef__iYQWnzWp.png',
];

const tabletImage = [
  'https://images.potioneer.com/Landing_Page_Banner/New_Open_Table/Banner-OTB-Tablet_1136x576_JhYl9p006.jpg',
  'https://images.potioneer.com/Landing_Page_Banner/Banner_Download_app/Tablet_1136x576_Download_app__M9XXSUV-.jpg',
  'https://images.potioneer.com/images-landing/Carosal/tablet/unsplash_673jcnrm8bM_welcome__1__x5Hke0NLQ.png',
  'https://images.potioneer.com/images-landing/Carosal/tablet/unsplash_673jcnrm8bM_Course__1__WSx8RL1hQ.png',
  'https://images.potioneer.com/images-landing/Carosal/tablet/unsplash_673jcnrm8bM_Chef__1__IU6_Dhj3j.png',
];

const mobileImage = [
  'https://images.potioneer.com/Landing_Page_Banner/New_Open_Table/Banner-OTB-mobile_V32eGgykx.jpg',
  'https://images.potioneer.com/Landing_Page_Banner/Banner_Download_app/Mobile_Download_app_m8U-cdEYy.jpg',
  'https://images.potioneer.com/images-landing/Carosal/mobile/unsplash_673jcnrm8bM_welcome__2__b1ETqP9F8.png',
  'https://images.potioneer.com/images-landing/Carosal/mobile/unsplash_673jcnrm8bM_Course__2__wZKxZoWji.png',
  'https://images.potioneer.com/images-landing/Carosal/mobile/unsplash_673jcnrm8bM_Chef__2__OCQWrOyKp.png',
];

const HiddenGemSection: FunctionComponent = () => {
  const router = useRouter();
  const theme = useTheme();
  const isDownsm = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  const overMd = useMediaQuery(theme.breakpoints.up('md'));

  const FloatingBox = styled('div')(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: overMd ? 22 : 0,
    zIndex: 1,
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      padding: '0px 40px',
      height: '74%',
    },

    [theme.breakpoints.down('sm')]: {
      padding: '0px 30px',
      height: '65%',
      marginBottom: '10%',
    },
  }));

  // Heaven On Earth
  // const auth = useRecoilValue(authState);
  // const userProfile = useRecoilValue(profileState);
  // const setBookSeatState = useSetRecoilState(bookSeatAtom);
  // const [isOpenAuthModal, setIsOpenAuthModal] = useRecoilState(signUpSignInModalState);
  // const [canNavigateToBooking, setCanNavigateToBooking] = useState(false);
  // const [autoBooking, setAutoBooking] = useState<string>('');

  // const [isButtonLoading, setIsButtonLoading] = useState<boolean>();

  // const [isVerifyPhone, setIsVerifyPhone] = useState(false);

  // const joinOpenTableOnClick = useCallback(
  //   (isVerifyPhone?: boolean) => {
  //     setIsButtonLoading(true);

  //     if (auth.auth) {
  //       // const selectTimeNew = new Date(data.bookingDateFrom);
  //       // selectTimeNew.setDate(selectTimeNew.getDate() - 1);

  //       get_access_token().then((token) => {
  //         post_join_open_table(
  //           {
  //             name: `${userProfile.firstName} ${userProfile.lastName}`,
  //             bookingNumber: 'PTNOTB529460',
  //             numberOfGuest: 1,
  //             bookingDate: '2023-09-15T11:30:00Z',
  //             courseId: 125,
  //             courseLocationId: 9971,
  //             // open table is not YOUR_LOCATION (Dine-at-home) location
  //             preferredLocation: 'CHEF',
  //           },
  //           token
  //         ).then((res) => {
  //           if (res?.code === '10004') {
  //             !isOpenAuthModal.open && setIsOpenAuthModal({ open: true, mode: 'sign-in' });
  //             setCanNavigateToBooking(true);
  //             setAutoBooking('joinOpenTableOnClick');
  //           } else if (res?.code === '200') {
  //             setBookSeatState({
  //               ...bookSeatAtomDefaultState,
  //               // location:
  //               //   preferLocation === 'YOUR_LOCATION'
  //               //     ? SelectLocation.OwnLocation
  //               //     : SelectLocation.Restaurant,
  //               location: SelectLocation.Restaurant,
  //             });
  //             if (userProfile.isVerifyPhoneNumber || isVerifyPhone) {
  //               router.push({
  //                 pathname: '/book-seat/[bookingId]',
  //                 query: { bookingId: res?.data?.id, openTable: true },
  //               });
  //             } else {
  //               // setCallbackUrl(`/book-seat/${res?.data?.id}?openTable=true`);
  //               setIsVerifyPhone(true);

  //               setIsButtonLoading(false);
  //             }
  //           }
  //         });
  //       });
  //     } else {
  //       !isOpenAuthModal.open && setIsOpenAuthModal({ open: true, mode: 'sign-in' });
  //       setCanNavigateToBooking(true);
  //       setAutoBooking('joinOpenTableOnClick');

  //       setIsButtonLoading(false);
  //     }
  //   },
  //   [auth.auth, userProfile, isOpenAuthModal.open, router, setBookSeatState, setIsOpenAuthModal]
  // );

  // // auto booking after login
  // useEffect(() => {
  //   if (
  //     auth.auth &&
  //     userProfile.id != 0 &&
  //     canNavigateToBooking &&
  //     autoBooking === 'joinOpenTableOnClick'
  //   ) {
  //     joinOpenTableOnClick();

  //     setCanNavigateToBooking(false);
  //     setAutoBooking('');
  //   }
  // }, [auth.auth, userProfile, canNavigateToBooking, autoBooking, joinOpenTableOnClick]);

  const ImageSlides = ({ images }: { images: string[] }) => {
    const theme = useTheme();
    const carouselRef = useRef<Slider | null>(null);
    const [currentIndex, setCurrentIndex] = useState(
      theme.direction === 'rtl' ? images.length - 1 : 0
    );
    const settings = {
      speed: 1000,
      dots: false,
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      rtl: Boolean(theme.direction === 'rtl'),
      beforeChange: (current: number, next: number) => setCurrentIndex(next),
      ...CarouselDots({
        rounded: true,
        sx: { mt: 3 },
      }),
    };
    if (isDownsm) {
      settings.dots = true;
    }

    const handlePrevious = () => {
      carouselRef.current?.slickPrev();
    };

    const handleNext = () => {
      carouselRef.current?.slickNext();
    };

    const TextShow = () => {
      if (currentIndex === 0) {
        return (
          <StackTransition
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={4}
          >
            <m.div variants={varFade().inRight} />
          </StackTransition>
        );
      } else if (currentIndex === 1) {
        return (
          <StackTransition
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={4}
          >
            <m.div variants={varFade().inRight} />
          </StackTransition>
        );
      } else if (currentIndex === 2) {
        return (
          <StackTransition
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={4}
          >
            <m.div variants={varFade().inRight}>
              <Typography variant={overMd ? 'h2' : 'h3'} color="#212B36">
                Welcome to the Newness of your {isDesktop && <br />} Private Dining Experience
              </Typography>
              <CardButton
                id="Herobanner_howitworks"
                style={{
                  marginTop: isDownsm ? '15%' : '5%',
                  textTransform: 'none',
                }}
                onClick={() => router.push(`/how-it-work`)}
              >
                How It Works
              </CardButton>
            </m.div>
          </StackTransition>
        );
      } else if (currentIndex === 3) {
        return (
          <StackTransition
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={4}
          >
            <m.div variants={varFade().inRight}>
              <Typography variant={overMd ? 'h2' : 'h3'} color="#212B36">
                Trending Course Menus of the Month
              </Typography>
              <Typography
                variant={overMd ? 'h3' : 'h6'}
                style={{
                  marginTop: isDownsm ? '10%' : '1%',
                }}
                color="#212B36"
              >
                Check out the lastest <br />
                course menus create by the Chefs
              </Typography>
              <CardButton
                id="Herobanner_explorecourse"
                style={{
                  marginTop: '3%',
                  textTransform: 'none',
                }}
                onClick={() => router.push(`/see-all-courses`)}
              >
                Check It Out
              </CardButton>
            </m.div>
          </StackTransition>
        );
      } else if (currentIndex === 4) {
        return (
          <StackTransition
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-start"
            spacing={4}
          >
            <m.div variants={varFade().inRight}>
              <Typography variant={overMd ? 'h2' : 'h3'} color="#212B36">
                Meet {isDownsm && <br />} Potioneer&apos;s Chefs
              </Typography>
              <Typography
                variant={overMd ? 'h3' : 'h6'}
                style={{
                  marginTop: isDownsm ? '10%' : '1%',
                  textTransform: 'none',
                }}
                color="#212B36"
              >
                Visit the chef profiles and {isDesktop && <br />}
                explore their culinary adventures
              </Typography>
              <CardButton
                id="Herobanner_explorechef"
                style={{
                  marginTop: '3%',
                  textTransform: 'none',
                }}
                onClick={() => {
                  const element = document.getElementById('know-the-chefs');
                  element?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'nearest',
                  });
                }}
              >
                Explore Our Chef
              </CardButton>
            </m.div>
          </StackTransition>
        );
      }
    };

    return (
      <RootStyle sx={{ borderRadius: 3 }}>
        <Slider ref={carouselRef} {...settings}>
          {images?.map((img, idx) => (
            <ImageSlide
              id={
                currentIndex === 0
                  ? 'Herobanner_intro'
                  : currentIndex === 1
                  ? 'Herobanner_downloadapp'
                  : ''
              }
              key={'Hidden_Gem_Section_' + idx}
              alt={`potioneer-img-${idx}`}
              image={img}
              isLink={idx === 1}
              handleOnClick={() => {
                // Heaven On Earth
                // if (idx === 0) {
                //   setIsButtonLoading(true);

                //   joinOpenTableOnClick();
                // }
                if (idx === 1) router.push('/download-app');
              }}
            />
          ))}
        </Slider>
        {currentIndex !== 1 ? <FloatingBox>{TextShow()}</FloatingBox> : <></>}
        {!isDownsm && (
          <CarouselArrowIndex
            index={currentIndex}
            total={images.length}
            onNext={handleNext}
            onPrevious={handlePrevious}
          />
        )}
      </RootStyle>
    );
  };

  return (
    <Box>
      <Container>
        <Box
          mt={isDesktop ? 5 : 6}
          mb={2}
          sx={{
            zIndex: 1,
            position: 'relative',
            borderRadius: 1,
            backgroundColor: 'white',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
          }}
          p={1}
        >
          <Stack direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
            <div
              style={{
                padding: '8px 8px 3px 8px',
                backgroundColor: 'rgba(24, 144, 255, 0.16)',
                borderRadius: 12,
              }}
            >
              <InfoRoundedIcon
                style={{
                  color: '#1890FF',
                }}
              />
            </div>
            <Typography variant="subtitle2" fontWeight={600} id="landing-top">
              Welcome to Potioneer&apos;s BETA version.
            </Typography>
          </Stack>
        </Box>
      </Container>
      <Container>
        <Box mt={2}>
          <ImageSlides
            images={
              overMd
                ? desktopImage.map((i) =>
                    resize_image({
                      url: i,
                      width: 1152,
                      height: 397,
                    })
                  )
                : isDesktop
                ? tabletImage.map((i) =>
                    resize_image({
                      url: i,
                      width: 851,
                    })
                  )
                : mobileImage.map((i) =>
                    resize_image({
                      url: i,
                      width: 567,
                    })
                  )
            }
          />
        </Box>
      </Container>

      <FacebookProvider appId="1913729215620894" chatSupport>
        <CustomChat
          pageId="1841315256092551"
          themeColor="#F15B40"
          greetingDialogDisplay="fade"
          minimized
        />
      </FacebookProvider>

      {/* Heaven On Earth */}
      {/* <VerifyPhoneModal
        isOpen={isVerifyPhone}
        onClose={() => {
          setIsVerifyPhone(false);

          // clear auto booking after login
          setCanNavigateToBooking(false);
          setAutoBooking('');
        }}
        callback={() => {
          joinOpenTableOnClick(true);
        }}
      /> */}
    </Box>
  );
};

export default HiddenGemSection;
