import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';
import Box from '@mui/material/Box';
import { useState, useRef, useMemo } from 'react';
import {
  CoursesData,
  get_courses,
  get_courses_next_available,
  PUBLISHED_NEW,
  POPULARITY,
} from '@services/course_public';
import CourseMenuCardSizeS, {
  CourseMenuCard,
} from '@components/course-menu-card/CourseMenuCardSizeS';
import CourseMenuCardSizeM from '@components/course-menu-card/CourseMenuCardSizeM';
import CourseMenuCardSizeL from '@components/course-menu-card/CourseMenuCardSizeL';
import CarouselArrows, { NextArrow, PrevArrow } from '@components/carousel/CarouselArrows';
import CarouselDots from '@components/carousel/CarouselDots';

import Slider from 'react-slick';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import { change_symbol, url_course_menu } from '@utils/formatUrl';

interface ParentCompProps {
  titleComp?: React.ReactNode;
  isCourseMenuCardSizeL?: boolean;
  typeListSection: string;
}

const RootStyle = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
}));

const SEE_ALL_COURSE = '/see-all-courses';
const SEEL_ALL_MOST_BOOKED_COURSES = '/see-all-most-booked-courses';
const SEEL_ALL_AVAILABLE_TIME = '/see-all-available-time';

const CourseMenuListSection = ({
  titleComp,
  isCourseMenuCardSizeL,
  typeListSection,
}: ParentCompProps) => {
  const theme = useTheme();
  const router = useRouter();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [courseMenus, setCourseMenus] = useState<CourseMenuCard[]>([]);
  const [courseListX, setCourseListX] = useState<CourseMenuCard[][]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [total, setTotal] = useState(0);

  const mapResponse = (c: CoursesData): CourseMenuCard => {
    return {
      id: c.id,
      isNewCourse: c.isNewCourse,
      coverImages: c.imageSignatureDishes?.map((i) => i.imageUrl),
      title: c.title,
      chefName: c.chef?.name,
      cuisineTypes: c.typeOfCuisines?.map((c) => c.name),
      location: c?.location?.[0],
      availableDate: c.timeAvailables?.[0]?.time,
      availableTime: c.timeAvailables?.map((t) => t.time),
      price: c.pricePerGuest,
      numberOfBook: c.numberOfBook,
    };
  };

  useMemo(() => {
    const fetchCoursesNew = () => {
      get_courses(1, isCourseMenuCardSizeL ? 12 : 16, PUBLISHED_NEW)
        .then((res) => {
          const coursemenus: CourseMenuCard[] = res?.data
            .flatMap((c) => c.data)
            .map((c) => mapResponse(c));
          setTotal(coursemenus?.length);
          setCourseMenus(coursemenus);
          const courseList: CourseMenuCard[][] = [];
          for (let i = 0; i < coursemenus.length; i += isCourseMenuCardSizeL ? 3 : 4) {
            courseList.push(coursemenus.slice(i, i + (isCourseMenuCardSizeL ? 3 : 4)));
          }
          setCourseListX(courseList);
        })
        .finally(() => setIsLoading(false));
    };

    const fetchCourseMostBooked = () => {
      get_courses(1, isCourseMenuCardSizeL ? 12 : 16, POPULARITY)
        .then((res) => {
          const coursemenus: CourseMenuCard[] = res?.data
            .flatMap((c) => c.data)
            .map((c) => mapResponse(c));
          setTotal(coursemenus?.length);
          setCourseMenus(coursemenus);
          const courseList: CourseMenuCard[][] = [];
          for (let i = 0; i < coursemenus.length; i += isCourseMenuCardSizeL ? 3 : 4) {
            courseList.push(coursemenus.slice(i, i + (isCourseMenuCardSizeL ? 3 : 4)));
          }
          setCourseListX(courseList);
        })
        .finally(() => setIsLoading(false));
    };

    const fetchCourseNextAvailable = () => {
      get_courses_next_available(16)
        .then((res) => {
          const coursemenus: CourseMenuCard[] = res?.data?.map((c) => mapResponse(c));
          setTotal(coursemenus?.length);
          setCourseMenus(coursemenus);
          const courseList: CourseMenuCard[][] = [];
          for (let i = 0; i < coursemenus.length; i += isCourseMenuCardSizeL ? 3 : 4) {
            courseList.push(coursemenus.slice(i, i + (isCourseMenuCardSizeL ? 3 : 4)));
          }
          setCourseListX(courseList);
        })
        .finally(() => setIsLoading(false));
    };

    if (typeListSection === 'MOST_BOOKED') {
      fetchCourseMostBooked();
      return;
    }
    if (typeListSection === 'NEXT_DINNER') {
      fetchCourseNextAvailable();
      return;
    }
    fetchCoursesNew();
  }, [isCourseMenuCardSizeL, typeListSection]);

  const TabletAndMobileView = () => (
    <Grid
      container
      spacing={2}
      sx={{
        flexWrap: 'nowrap',
        overflow: 'auto',
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
        pb: 0,
      }}
      style={{ marginBottom: 0 }}
    >
      {isLoading ? (
        <>
          {Array.from(new Array(isCourseMenuCardSizeL ? 12 : 16)).map((_, idx) => (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              key={idx}
              xs={12}
              sm={6}
              // md={4}
              md="auto"
              lg={3}
              sx={{
                [theme.breakpoints.down('md')]: {
                  ml: idx == 0 ? 2 : 0,
                  mr: idx == total - 1 ? 2 : 0,
                },
              }}
            >
              {isCourseMenuCardSizeL ? (
                <Card
                  sx={{
                    height: 422,
                    width: 343,
                  }}
                >
                  <Stack>
                    <Skeleton variant="rectangular" height={257.25} />
                    <Stack sx={{ p: 2 }}>
                      <Skeleton width={'80%'} />
                      <Skeleton width={'60%'} />
                    </Stack>
                  </Stack>
                </Card>
              ) : (
                <Card
                  sx={{
                    height: 358,
                    width: 270,
                  }}
                >
                  <Stack>
                    <Skeleton variant="rectangular" height={202.5} />
                    <Stack sx={{ p: 2 }}>
                      <Skeleton width={'80%'} />
                      <Skeleton width={'60%'} />
                    </Stack>
                  </Stack>
                </Card>
              )}
            </Grid>
          ))}
        </>
      ) : (
        <>
          {courseMenus.map((course, idx) => (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              key={idx}
              xs={12}
              sm={6}
              // md={4}
              md="auto"
              lg={3}
              sx={{
                [theme.breakpoints.down('md')]: {
                  ml: idx == 0 ? 2 : 0,
                  mr: idx == total - 1 ? 2 : 0,
                },
              }}
            >
              {isCourseMenuCardSizeL ? (
                // tablet and mobile view
                <CourseMenuCardSizeM
                  id={
                    typeListSection === 'NEW'
                      ? `ExploreNewCourse_${change_symbol(course?.title ?? '')}`
                      : typeListSection === 'MOST_BOOKED'
                      ? `MostBooked_${change_symbol(course?.title ?? '')}`
                      : typeListSection === 'NEXT_DINNER'
                      ? `NextDinner_${change_symbol(course?.title ?? '')}`
                      : undefined
                  }
                  courseMenu={course}
                  includeAvailableTime={typeListSection === 'NEXT_DINNER'}
                  onClick={() => router.push(`${url_course_menu(course?.chefName, course?.title)}`)}
                />
              ) : (
                <CourseMenuCardSizeS
                  id={
                    typeListSection === 'NEW'
                      ? `ExploreNewCourse_${change_symbol(course?.title ?? '')}`
                      : typeListSection === 'MOST_BOOKED'
                      ? `MostBooked_${change_symbol(course?.title ?? '')}`
                      : typeListSection === 'NEXT_DINNER'
                      ? `NextDinner_${change_symbol(course?.title ?? '')}`
                      : undefined
                  }
                  courseMenu={course}
                  includeAvailableTime={typeListSection === 'NEXT_DINNER'}
                  onClick={() => router.push(`${url_course_menu(course?.chefName, course?.title)}`)}
                />
              )}
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );

  const DesktopView = () => {
    const carouselRef = useRef<Slider | null>(null);
    const theme = useTheme();
    const settings = {
      accessibility: true,
      dots: true,
      autoplay: false,
      infinite: false,
      slidesToShow: 1,
      swipe: false,
      slidesToScroll: 1,
      rtl: Boolean(theme.direction === 'rtl'),
      ...CarouselDots({
        sx: { mt: 4.5 },
      }),
      arrows: true,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
    };

    const CoursesCarousel = ({ courses }: { courses: CourseMenuCard[] }) => (
      <Grid container spacing={3}>
        {courses.map((c, idx) =>
          isCourseMenuCardSizeL ? (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              key={idx}
              xs={12}
              sm={6}
              // md={4}
              md="auto"
              lg={4}
            >
              <CourseMenuCardSizeL
                id={
                  typeListSection === 'NEW'
                    ? `ExploreNewCourse_${change_symbol(c?.title ?? '')}`
                    : typeListSection === 'MOST_BOOKED'
                    ? `MostBooked_${change_symbol(c?.title ?? '')}`
                    : typeListSection === 'NEXT_DINNER'
                    ? `NextDinner_${change_symbol(c?.title ?? '')}`
                    : undefined
                }
                courseMenu={c}
                includeAvailableTime={typeListSection === 'NEXT_DINNER'}
                onClick={() => router.push(`${url_course_menu(c?.chefName, c?.title)}`)}
              />
            </Grid>
          ) : (
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              item
              key={idx}
              xs={12}
              sm={6}
              // md={4}
              md="auto"
              lg={3}
            >
              <CourseMenuCardSizeS
                id={
                  typeListSection === 'NEW'
                    ? `ExploreNewCourse_${change_symbol(c?.title ?? '')}`
                    : typeListSection === 'MOST_BOOKED'
                    ? `MostBooked_${change_symbol(c?.title ?? '')}`
                    : typeListSection === 'NEXT_DINNER'
                    ? `NextDinner_${change_symbol(c?.title ?? '')}`
                    : undefined
                }
                courseMenu={c}
                includeAvailableTime={typeListSection === 'NEXT_DINNER'}
                onClick={() => router.push(`${url_course_menu(c?.chefName, c?.title)}`)}
              />
            </Grid>
          )
        )}
      </Grid>
    );

    return (
      <Container>
        <RootStyle>
          {isLoading ? (
            <Grid container spacing={3}>
              {Array.from(Array(isCourseMenuCardSizeL ? 3 : 4).keys()).map((_, idx) =>
                isCourseMenuCardSizeL ? (
                  <Grid item key={idx} xs={12} sm={6} md={4} lg={4}>
                    <Card
                      sx={{
                        height: 422,
                        width: 368,
                      }}
                    >
                      <Stack>
                        <Skeleton variant="rectangular" height={276} />
                        <Stack sx={{ p: 2 }}>
                          <Skeleton width={'80%'} />
                          <Skeleton width={'60%'} />
                        </Stack>
                      </Stack>
                    </Card>
                  </Grid>
                ) : (
                  <Grid item key={idx} xs={12} sm={6} md={4} lg={3}>
                    <Card
                      sx={{
                        height: 358,
                        width: 270,
                      }}
                    >
                      <Stack>
                        <Skeleton variant="rectangular" height={202.5} />
                        <Stack sx={{ p: 2 }}>
                          <Skeleton width={'80%'} />
                          <Skeleton width={'60%'} />
                        </Stack>
                      </Stack>
                    </Card>
                  </Grid>
                )
              )}
            </Grid>
          ) : (
            <Slider ref={carouselRef} {...settings}>
              {courseListX.length > 0 &&
                courseListX.map((courseSet, idx) => {
                  return <CoursesCarousel courses={courseSet} key={idx} />;
                })}
            </Slider>
          )}
        </RootStyle>
      </Container>
    );
  };

  return (
    <Box
      mb={{
        xs: 6,
        sm: 6,
      }}
      sx={{
        filter: 'drop-shadow(0px 1px 2px rgba(145, 158, 171, 0.24))',
      }}
    >
      <Container>
        <Grid container justifyContent={'space-between'} alignItems="flex-end" mb={4}>
          <Grid item xs={10}>
            {titleComp}
          </Grid>
          {typeListSection !== 'NEXT_DINNER' && (
            <Grid
              item
              xs={2}
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                id={
                  typeListSection === 'NEW'
                    ? 'ExploreNewCourse_SeeAll'
                    : typeListSection === 'MOST_BOOKED'
                    ? 'MostBooked_SeeAll'
                    : ''
                }
                disableRipple
                onClick={() => {
                  if (typeListSection === 'MOST_BOOKED') {
                    router.push(SEEL_ALL_MOST_BOOKED_COURSES);
                    return;
                  } else if (typeListSection === 'NEXT_DINNER') {
                    router.push(SEEL_ALL_AVAILABLE_TIME);
                    return;
                  }
                  router.push(SEE_ALL_COURSE);
                }}
                sx={{
                  alignSelf: 'flex-end',
                  '&:hover': {
                    background: 'transparent',
                    opacity: 0.5,
                  },
                }}
              >
                <Typography variant="overline">SEE ALL</Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      </Container>
      {isDesktop && <DesktopView />}
      {!isDesktop && <TabletAndMobileView />}
    </Box>
  );
};

export default CourseMenuListSection;
