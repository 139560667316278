import Modal from '@components/Modal';
import { FunctionComponent, useState } from 'react';
import SignUp from './SignUp';
import VerifyYourEmail from './VerifyYourEmail';

type State = 'sign-up' | 'verify-your-emil';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refId?: string;
}

const SignUpModal: FunctionComponent<Props> = ({ isOpen, onClose, refId }) => {
  const [email, setEmail] = useState<string>('');
  const [state, setState] = useState<State>('sign-up');

  const onSignUpSuccess = (email: string) => {
    setEmail(email);
    setState('verify-your-emil');
  };

  const onModalClose = () => {
    onClose();

    setTimeout(() => {
      setState('sign-up');
    }, 300);
  };

  const Content = () => {
    switch (state) {
      case 'sign-up':
        return <SignUp refId={refId} onSignUpSuccess={onSignUpSuccess} />;
      case 'verify-your-emil':
        return <VerifyYourEmail email={email} />;
      default:
        return false;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} maxWidth="xs" fullWidth>
      {Content()}
    </Modal>
  );
};

export default SignUpModal;
