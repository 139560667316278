import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useEffect, useState, ReactElement, useRef } from 'react';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { styled, useTheme } from '@mui/material/styles';
import { get_chef_random, ChefRandom } from '@services/chef_public';
import ChefCardWithPorfolioButton from '@components/landing/ChefCardWithPortfolioButton';
import { Box } from '@mui/material';
import CarouselArrows from '@components/carousel/CarouselArrows';
import Slider from 'react-slick';

const RootStyle = styled(Box)(({ theme }) => ({
  overflow: 'hidden',
  position: 'relative',
}));

const settings = {
  dots: false,
  arrows: true,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  rtl: true,
};

const ChefsListSection = () => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [total, setTotal] = useState(0);
  const [chefsData, setCheftsData] = useState<ChefRandom[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    get_chef_random()
      .then((res) => {
        setCheftsData(res?.data);
        setTotal(res?.data[0]?.total);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const DesktopChefList = (): ReactElement => {
    const scrollref = useRef<HTMLDivElement | null>(null);

    const handlePrevious = () => {
      scrollref.current?.scroll({
        left: scrollref.current?.scrollLeft - scrollref.current?.clientWidth,
        behavior: 'smooth',
      });
      console.log('handlePrevious');
    };

    const handleNext = () => {
      scrollref.current?.scroll({
        left: scrollref.current?.scrollLeft + scrollref.current?.clientWidth,
        behavior: 'smooth',
      });
      console.log('handleNext');
    };

    return (
      <Container>
        <Box mb={10}>
          <RootStyle>
            <CarouselArrows
              filled
              showLeftArrow
              showRightArrow
              color={'black'}
              onNext={handleNext}
              onPrevious={handlePrevious}
              sx={{
                '& .arrow': {
                  '&.left': { ml: isDesktop ? 2.5 : 1.5 },
                  '&.right': { mr: isDesktop ? 2.5 : 1.5 },
                },
              }}
            >
              <Grid
                container
                component="div"
                ref={scrollref}
                flexWrap="nowrap"
                overflow="scroll"
                spacing={{ xs: 2, md: 3 }}
                sx={{
                  scrollbarWidth: 'none',
                  '::-webkit-scrollbar': {
                    display: 'none',
                  },
                }}
              >
                {isLoading ? (
                  <>
                    {Array.from(new Array(12)).map((_, idx) => (
                      <Grid item key={idx}>
                        <ChefCardWithPorfolioButton
                          info={{
                            id: 0,
                            chefImg: '',
                            name: '',
                            cuisineType: [''],
                          }}
                          isLoading={true}
                        />
                      </Grid>
                    ))}
                  </>
                ) : (
                  <>
                    {chefsData
                      .flatMap((chef) => chef.data)
                      .filter((chef) => chef.imageProfile)
                      .map((chef, idx) => (
                        <Grid item key={idx}>
                          <ChefCardWithPorfolioButton
                            info={{
                              id: chef.id,
                              chefImg: chef.imageProfile?.imageUrl,
                              name: chef.name,
                              cuisineType: chef.typeOfCuisines
                                .filter((c) => c.isActive)
                                .map((c) => c.name),
                            }}
                            isLoading={isLoading}
                          />
                        </Grid>
                      ))}
                  </>
                )}
              </Grid>
            </CarouselArrows>
          </RootStyle>
        </Box>
      </Container>
    );
  };

  const MobileChefList = (): ReactElement => (
    <Grid
      container
      flexWrap="nowrap"
      overflow="auto"
      spacing={2}
      pb={6}
      sx={{
        scrollbarWidth: 'none',
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      {chefsData
        .flatMap((chef) => chef.data)
        .filter((chef) => chef.imageProfile)
        .map((chef, idx) => {
          return (
            <Grid
              key={idx}
              item
              sx={{
                ml: idx == 0 ? 2 : 0,
                mr: idx == total - 1 ? 2 : 0,
              }}
            >
              <ChefCardWithPorfolioButton
                info={{
                  id: chef.id,
                  chefImg: chef.imageProfile?.imageUrl,
                  name: chef.name,
                  cuisineType: chef.typeOfCuisines.filter((c) => c.isActive).map((c) => c.name),
                }}
              />
            </Grid>
          );
        })}
    </Grid>
  );

  return (
    <>
      <Container id="know-the-chefs">
        <Grid container direction={isMobile ? 'column' : 'row'} mb={4}>
          <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
            Let&rsquo;s Get to Know&nbsp;
          </Typography>
          <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700} color="#F15B40">
            the Chefs
          </Typography>
        </Grid>
      </Container>
      {isDesktop && <DesktopChefList />}
      {!isDesktop && <MobileChefList />}
    </>
  );
};

export default ChefsListSection;
