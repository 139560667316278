import Modal from '@components/Modal';
import { Box, Button, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import { FunctionComponent } from 'react';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const ErrorFullInvitationModal: FunctionComponent<Props> = ({ isOpen, onClose }) => {
  const onModalClose = () => {
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onModalClose} maxWidth="xs">
      <Box px={5} pt={6} pb={3}>
        <Typography variant="h4" align="center">
          Invitaion is full
        </Typography>
        <Box py={3}>
          <Typography variant="body2" align="center">
            Sorry, the inviation you are using is full.
          </Typography>
          <Typography variant="body2" align="center">
            We hope to be
          </Typography>
          <Typography variant="body2" align="center">
            able to offer more invitations in the future!
          </Typography>
        </Box>
        <Box textAlign="center">
          <Button variant="contained" onClick={onModalClose}>
            Ok
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ErrorFullInvitationModal;
