import HiddenGemSection from '@components/landing/HiddenGemSection';
import OpenTableSection from '@components/landing/OpenTableSection';
import DestinationsSection from '@components/landing/DestinationsSection';
import ChefsListSection from '@components/landing/ChefsListSection';
import CourseMenuListSection from '@components/landing/CourseMenuListSection';
import BecomeChefBanner from '@components/landing/BecomeChefBanner';
import DinersReviewSection from '@components/landing/DinersReviewSection';
import PotioneerFooter from 'src/layouts/Footer';
import ErrorFullInvitationModal from '@components/authentication/Invitation/ErrorFullInvitationModal';
import SignUpModal from '@components/authentication/Invitation/SignUpModal';
import DialogNewFeture from '@components/landing/DialogNewFeture';
import DialogOpenTableRecommend from '@components/landing/DialogOpenTableRecommend';
import Page from '@components/Page';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { get_invitation_check_limit } from '@services/invitation';
import { NextPage } from 'next';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { profileState } from '@atoms/profile';
import { useRecoilValue } from 'recoil';
import { authState } from '@atoms/auth';

const Index: NextPage = () => {
  const theme = useTheme();
  const router = useRouter();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const auth = useRecoilValue(authState);
  const [refId, setRefId] = useState<string>('');
  const [isOpenSignUpModal, setIsOpenSignUpModal] = useState<boolean>(false);
  const [isOpenRefIdLimitErrorModal, setIsOpenRefIdLimitErrorModal] = useState<boolean>(false);
  const [dialogNewFetureOpen, setDialogNewFetureOpen] = useState(true);
  const [dialogOpenTableRecommendOpen, setDialogOpenTableRecommendOpen] = useState(true);

  const profile = useRecoilValue(profileState);

  const onClickDialogNewFeture = () => {
    window.localStorage.setItem('dialogNewFeture', 'true');
    router.push('/venues');
    setDialogNewFetureOpen(false);
  };

  useEffect(() => {
    const isOpenDialogRecommend = window.localStorage.getItem('dialogOpenTableRecommend');
    if (isOpenDialogRecommend) {
      setDialogOpenTableRecommendOpen(false);
    }
  }, []);

  useEffect(() => {
    const { refId } = router.query;

    if (refId) {
      get_invitation_check_limit(refId as string).then(({ code }) => {
        if (code !== '200') {
          setIsOpenRefIdLimitErrorModal(true);
          return;
        }

        setRefId(refId as string);
        setIsOpenSignUpModal(true);
      });
    }
  }, [router]);

  useEffect(() => {
    const check = window.localStorage.getItem('dialogNewFeture');
    if (check) {
      setDialogNewFetureOpen(false);
    } else if (!profile.isChef) {
      setDialogNewFetureOpen(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile.isChef]);

  return (
    <Page isLanding isLayerOnePage title="Potioneer" bgcolor={'#fafafa'}>
      <HiddenGemSection />
      <OpenTableSection />
      <DestinationsSection />
      <ChefsListSection />
      <CourseMenuListSection
        isCourseMenuCardSizeL={true}
        typeListSection="NEW"
        titleComp={
          <>
            <Grid container alignItems="center">
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
                Check out&nbsp;
              </Typography>
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700} color="#F15B40">
                New Course&nbsp;
              </Typography>
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
                Menus from Chefs
              </Typography>
            </Grid>
          </>
        }
      />
      <CourseMenuListSection
        typeListSection="MOST_BOOKED"
        titleComp={
          <>
            <Grid container alignItems="center">
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
                Don’t Miss Out the&nbsp;
              </Typography>
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700} color="#F15B40">
                Most Booked&nbsp;
              </Typography>
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
                Courses
              </Typography>
            </Grid>
          </>
        }
      />
      <CourseMenuListSection
        typeListSection="NEXT_DINNER"
        titleComp={
          <>
            <Grid container maxWidth={isMobile ? '265px' : '60%'} alignItems="center">
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
                Available for Your&nbsp;
              </Typography>
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700} color="#F15B40">
                Next&nbsp;
              </Typography>
              <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700}>
                Dinner
              </Typography>
            </Grid>
          </>
        }
      />
      <BecomeChefBanner />
      <DinersReviewSection />
      <PotioneerFooter />
      <ErrorFullInvitationModal
        isOpen={isOpenRefIdLimitErrorModal}
        onClose={() => {
          setIsOpenRefIdLimitErrorModal(false);

          window.history.pushState({}, 'landing', `/`);
        }}
      />
      <SignUpModal
        refId={refId}
        isOpen={isOpenSignUpModal}
        onClose={() => {
          setIsOpenSignUpModal(false);

          window.history.pushState({}, 'landing', `/`);
        }}
      />
      {dialogNewFetureOpen && (
        <DialogNewFeture
          open={dialogNewFetureOpen}
          handleClose={() => setDialogNewFetureOpen(false)}
          onClick={onClickDialogNewFeture}
        />
      )}
      {dialogOpenTableRecommendOpen && (
        <DialogOpenTableRecommend
          open={dialogOpenTableRecommendOpen}
          handleClose={() => setDialogOpenTableRecommendOpen(false)}
          onClick={() => setDialogOpenTableRecommendOpen(false)}
        />
      )}
    </Page>
  );
};

export default Index;
