import { post, get, get2 } from '@utils/fetch';

export interface Destination {
  id: number;
  name: string;
  lookupKey: string;
  imageId: number;
  image: Image;
  description: string;
  sequence: number;
}

export interface Image {
  id: number;
  imageUrl: string;
  thumbnailUrl: string;
}
export interface LocationDetail {
  locationId: number;
  locationName: string;
  locationType: string;
  locationRemarks: string;
  isParkingAvailable: boolean;
  address: string;
  distance: any;
  latitude: string;
  longitude: string;
  maxCapacity: number;
  venueFee: any;
  imageUrl: string;
  chefName: string;
}

export async function getDestinations(): Promise<Destination[]> {
  const res = await get2(`${process.env.NEXT_PUBLIC_API_URL}/destination`);
  return res.data[0].data;
}

export async function getDestinationByName(name: string): Promise<Destination[]> {
  const res = await get2(`${process.env.NEXT_PUBLIC_API_URL}/destination?name=${name}`);
  return res.data[0].data;
}

export async function getLocationDetail(
  name: string,
  limit: number,
  page: number,
  sortBy: string
): Promise<any> {
  const res = await post(`${process.env.NEXT_PUBLIC_API_URL}/v2/destination/search`, {
    destination: name,
    limit: limit,
    page: page,
    sortBy: sortBy,
  });
  return res.data[0];
}
