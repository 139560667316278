import * as React from 'react';
import { useState, useRef } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Modal from '@components/Modal';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Slider from 'react-slick';
import { CarouselDots } from '@components/carousel';
import {
  NextArrowOpenTableRecommend,
  PrevArrowOpenTableRecommend,
} from '@components/carousel/CarouselArrows';
import { resize_image } from '@utils/image';

type RootStyleProps = {
  isMobile: boolean;
};

const RootStyle = styled(Box)<RootStyleProps>(({ theme, isMobile }) => ({
  '& .slick-list': {
    borderRadius: Number(theme.shape.borderRadius) * 0,
  },
  width: isMobile ? '100%' : 375,
}));

type DialogOpenTableRecommendProps = {
  open: boolean;
  handleClose: () => void;
  onClick: () => void;
};

const imageOpenTableRecommendList = [
  'https://images.potioneer.com/images-landing/open-table-recommend/1_53DAsGsYn.svg',
  resize_image({
    url: 'https://images.potioneer.com/images-landing/open-table-recommend/2_n74n4CCy0.png',
    width: 375,
    height: 540,
  }),
  resize_image({
    url: 'https://images.potioneer.com/images-landing/open-table-recommend/3_Cd62anljQ.png',
    width: 375,
    height: 540,
  }),
  'https://images.potioneer.com/images-landing/open-table-recommend/4_6xRdcDDQW.svg',
  'https://images.potioneer.com/images-landing/open-table-recommend/5_c3CcBzQd4.svg',
];

export default function DialogOpenTableRecommend(props: DialogOpenTableRecommendProps) {
  const theme = useTheme();
  const windowWidthSize = useRef(window.innerWidth);
  const isDown426px = useMediaQuery(theme.breakpoints.down(426));

  const carouselRef = useRef<Slider | null>(null);
  const [currentIndex, setCurrentIndex] = useState(
    theme.direction === 'rtl' ? imageOpenTableRecommendList.length - 1 : 0
  );

  const settings = {
    className: 'slider variable-width',
    dots: true,
    arrows: true,
    autoplay: false,
    infinite: false,
    variableWidth: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
    ...CarouselDots({
      rounded: true,
      sx: { m: 0, p: 0 },
    }),
    nextArrow: <NextArrowOpenTableRecommend />,
    prevArrow: <PrevArrowOpenTableRecommend />,
  };

  return (
    <Modal
      idButtonClose="Modal_webintro_close"
      isOpen={props.open}
      onClose={() => {
        localStorage.setItem('dialogOpenTableRecommend', 'true');
        props.handleClose();
      }}
      fullScreen={isDown426px}
      maxWidth="xs"
      isBackdropClick
    >
      <Stack direction="column" justifyContent="flex-start" alignItems="center" minHeight="669px">
        <RootStyle isMobile={isDown426px}>
          <Slider ref={carouselRef} {...settings}>
            {imageOpenTableRecommendList.map((img, idx) => (
              <Box
                width={isDown426px ? '100%' : '375px'}
                height={isDown426px ? undefined : '540px'}
                key={idx}
              >
                <img
                  src={img}
                  alt={`${idx}`}
                  style={{
                    width: isDown426px ? windowWidthSize.current : 375,
                  }}
                />
              </Box>
            ))}
          </Slider>
        </RootStyle>

        {currentIndex === imageOpenTableRecommendList.length - 1 && (
          <Box width={'100%'} mt={'17.5px'} mb={'35.52px'} px={3}>
            <Button
              id="Modal_webintro_Start"
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                localStorage.setItem('dialogOpenTableRecommend', 'true');
                props.onClick();
              }}
              sx={{ color: '#D7D3CC' }}
            >
              Let’s Get Started
            </Button>
          </Box>
        )}
      </Stack>
    </Modal>
  );
}
