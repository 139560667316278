import Box from '@mui/material/Box';
import Card, { CardProps } from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/system';
import { useState, useRef, memo } from 'react';
import Slider from 'react-slick';
import CarouselDots from '@components/carousel/CarouselDots';
import Image from '@components/Image';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import { format } from 'date-fns';
import { fTime } from '@utils/formatTime';
import { fCurrencyInteger } from '@utils/formatNumber';
import { CourseMenuCard } from '@components/course-menu-card/CourseMenuCardSizeS';
import { resize_image } from '@utils/image';
import useMediaQuery from '@mui/material/useMediaQuery';

const StatusLabel = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: 24,
  left: 16,
  padding: '1px 8px',
  background: theme.palette.primary.main,
  borderRadius: '6px',
  color: '#ffffff',
  fontWeight: 700,
  fontSize: 12,
  lineHeight: '20px',
}));

const CardText = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
  width: '100%',
});

const CardText2 = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-word',
  display: '-webkit-box',
  WebkitLineClamp: '2',
  WebkitBoxOrient: 'vertical',
  width: '100%',
  height: '36px',
});

const ImageSlide = ({ alt, image }: { alt: string; image: string }) => {
  return (
    <Box position="relative" paddingTop="100%" sx={{ cursor: 'pointer' }}>
      <Image
        src={image}
        alt={alt}
        sx={{
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          width: '343px',
          height: '257.25px',
        }}
      />
    </Box>
  );
};

interface CourseMenuCardSizeMProps extends CardProps {
  id: string | undefined;
  courseMenu: CourseMenuCard;
  includeAvailableTime: boolean;
  onClick?: VoidFunction;
}

const CourseMenuCardSizeM = ({
  id,
  courseMenu,
  includeAvailableTime,
  onClick,
  ...other
}: CourseMenuCardSizeMProps) => {
  const {
    coverImages,
    isNewCourse,
    title,
    chefName,
    cuisineTypes,
    location,
    availableDate,
    availableTime,
    price,
    numberOfBook,
  } = courseMenu;

  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const cuisines = cuisineTypes?.join(' • ');
  const formattedPrice = fCurrencyInteger(price);
  const formattedNumberOfServing = fCurrencyInteger(numberOfBook);

  const ImageSlides = ({ images }: { images: string[] }) => {
    const carouselRef = useRef<Slider | null>(null);
    const settings = {
      speed: 800,
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      rtl: Boolean(theme.direction === 'rtl'),
      ...CarouselDots({
        rounded: true,
        sx: {
          mt: -14,
          '& li': {
            opacity: 0.5,
          },
          '& li.slick-active': {
            opacity: 0.99,
          },
        },
        color: '#ffffff',
      }),
    };

    if (isDownMd) images = images?.slice(0, 5);

    return (
      <Card sx={{ borderRadius: 0, height: 257.25, width: 343 }}>
        {images ? (
          <Slider ref={carouselRef} {...settings}>
            {images?.map((img, idx) => (
              <div
                onClick={onClick}
                style={{ cursor: 'pointer' }}
                key={'Course_Menu_Card_Size_M_' + idx}
              >
                <ImageSlide alt={`potioneer-img-${idx}`} image={img} />
              </div>
            ))}
          </Slider>
        ) : (
          <ImageSlide
            image={resize_image({
              url: '/images/profile-background.png',
              width: 343,
            })}
            alt="profile-background"
          />
        )}
      </Card>
    );
  };

  return (
    <Card
      id={id}
      sx={{
        height: includeAvailableTime ? 434 : 405,
        width: 343,
      }}
      {...other}
    >
      <ImageSlides
        images={coverImages?.map((v) =>
          resize_image({
            url: v,
            width: 343,
          })
        )}
      />
      <CardContent
        sx={{
          ':hover': {
            cursor: 'pointer',
          },
          p: 2,
        }}
        onClick={onClick}
      >
        {isNewCourse && <StatusLabel>NEW</StatusLabel>}
        <CardText gutterBottom variant="subtitle2" marginBottom="0px">
          {title}
        </CardText>
        <CardText2 variant="caption" color={theme.palette.grey[600]} marginBottom="0px">
          {chefName} • {cuisines}
        </CardText2>
        <Stack
          direction="row"
          spacing={1}
          marginBottom={includeAvailableTime && availableTime && availableDate ? '8px' : '24px'}
        >
          <img
            src="https://images.potioneer.com/Icon_MinimalsCC/SVG/ic_location_akGBY_dgE.svg"
            alt="check icon"
            width={16}
            height={16}
          />
          <CardText gutterBottom variant="caption" sx={{ color: 'text.disabled' }}>
            {location?.count && location?.count > 1
              ? location?.name + ' and more...'
              : location?.name}
          </CardText>
        </Stack>
        {includeAvailableTime && availableTime && availableDate && (
          <Stack direction="row" spacing={1} marginBottom="16px" alignItems={'center'}>
            <img
              src="https://images.potioneer.com/Icon_MinimalsCC/SVG/ic_clock_ID2zfJcMR.svg"
              alt="check icon"
              width={16}
              height={16}
            />
            <Typography variant="caption" color="#919EAB">
              {format(new Date(availableDate), 'dd MMM yyyy')}
            </Typography>
            {availableTime.map((time, idx) => (
              <Box
                key={idx}
                sx={{
                  backgroundColor: '#C8D9D9',
                  borderRadius: '2px',
                  padding: '1px 4px',
                }}
              >
                <Typography fontSize={12} fontWeight={700}>
                  {fTime(time)}
                </Typography>
              </Box>
            ))}
          </Stack>
        )}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}
          marginBottom="24px"
        >
          <Typography gutterBottom variant="caption" component="div">
            From&nbsp;
            <Box fontSize={16} fontWeight={600} display="inline">
              ฿{formattedPrice}
            </Box>
            &nbsp;/ person
          </Typography>
          {numberOfBook && numberOfBook !== 0 ? (
            <Typography gutterBottom variant="caption" color={theme.palette.grey[600]}>
              {formattedNumberOfServing}
              &nbsp;Booked
            </Typography>
          ) : (
            <></>
          )}
        </Stack>
      </CardContent>
    </Card>
  );
};

export default memo(CourseMenuCardSizeM);
