import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useState, useEffect, useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { FunctionComponent, useCallback } from 'react';
import { useRouter } from 'next/router';
import Slider from 'react-slick';
import { CarouselDots, CarouselArrows } from '@components/carousel';
import { NextArrow, PrevArrow } from '@components/carousel/CarouselArrows';
import { get_open_table, post_join_open_table } from 'src/services/booking';
import { SelectLocation, OpenTableData } from 'src/types/book-seat';
import OpenTableCard from '@components/course-menu-card/OpenTableCard';
import { change_symbol, url_course_menu } from '@utils/formatUrl';
import { get_access_token } from 'src/services/auth';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { profileState } from '@atoms/profile';
import { authState, signUpSignInModalState } from '@atoms/auth';
import VerifyPhoneModal from '@components/book-seat/VerifyPhoneModal';
import { bookSeatAtom, bookSeatAtomDefaultState } from '@atoms/book-seat';
import Button from '@mui/material/Button';

const RootStyle = styled(Box)(({ theme }) => ({
  '& .slick-list': {
    borderRadius: Number(theme.shape.borderRadius) * 0,
  },
}));

const OpenTableSection: FunctionComponent = () => {
  const router = useRouter();
  const theme = useTheme();
  const isUpSm = useMediaQuery(theme.breakpoints.up('sm')); // 600
  const isUpMd = useMediaQuery(theme.breakpoints.up('md')); // 900
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg')); // 1200
  const isUp720px = useMediaQuery(theme.breakpoints.up(720));
  const isUp815px = useMediaQuery(theme.breakpoints.up(815));
  const isUp391px = useMediaQuery(theme.breakpoints.up(391));

  const auth = useRecoilValue(authState);
  const userProfile = useRecoilValue(profileState);
  const setBookSeatState = useSetRecoilState(bookSeatAtom);
  const [isOpenAuthModal, setIsOpenAuthModal] = useRecoilState(signUpSignInModalState);
  const [canNavigateToBooking, setCanNavigateToBooking] = useState(false);
  const [autoBooking, setAutoBooking] = useState<string>('');
  const [autoJoinOpenTable, setAutoJoinOpenTable] = useState<OpenTableData>();

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>();
  const [isIndexButtonLoading, setIsIndexButtonLoading] = useState<number>();

  const [openTableLists, setOpenTableLists] = useState<OpenTableData[]>([]);
  const [isVerifyPhone, setIsVerifyPhone] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState('');

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const fetchOpenTable = async () => {
      get_open_table(1, isUpSm ? 12 : 6).then((res) => {
        if (res?.code !== '200') {
          return;
        }

        setOpenTableLists(res?.data?.[0]?.data);
      });
    };

    fetchOpenTable();
  }, [isUpSm]);

  const joinOpenTableOnClick = useCallback(
    (data: OpenTableData, isVerifyPhone?: boolean) => {
      setIsButtonLoading(true);

      if (auth.auth) {
        // const selectTimeNew = new Date(data.bookingDateFrom);
        // selectTimeNew.setDate(selectTimeNew.getDate() - 1);

        const courseLocationId = data.courseLocation.id;
        get_access_token().then((token) => {
          post_join_open_table(
            {
              name: `${userProfile.firstName} ${userProfile.lastName}`,
              bookingNumber: data.bookingNumber,
              numberOfGuest: 1,
              bookingDate: data.bookingDateFrom,
              courseId: data.courseId,
              courseLocationId: courseLocationId,
              // open table is not YOUR_LOCATION (Dine-at-home) location
              preferredLocation: 'CHEF',
            },
            token
          ).then((res) => {
            if (res?.code === '10004') {
              !isOpenAuthModal.open && setIsOpenAuthModal({ open: true, mode: 'sign-in' });
              setCanNavigateToBooking(true);
              setAutoBooking('joinOpenTableOnClick');
            } else if (res?.code === '200') {
              setBookSeatState({
                ...bookSeatAtomDefaultState,
                // location:
                //   preferLocation === 'YOUR_LOCATION'
                //     ? SelectLocation.OwnLocation
                //     : SelectLocation.Restaurant,
                location: SelectLocation.Restaurant,
              });
              if (userProfile.isVerifyPhoneNumber || isVerifyPhone) {
                router.push({
                  pathname: '/book-seat/[bookingId]',
                  query: { bookingId: res?.data?.id, openTable: true },
                });
              } else {
                setCallbackUrl(`/book-seat/${res?.data?.id}?openTable=true`);
                setIsVerifyPhone(true);

                setIsButtonLoading(false);
              }
            }
          });
        });
      } else {
        !isOpenAuthModal.open && setIsOpenAuthModal({ open: true, mode: 'sign-in' });
        setCanNavigateToBooking(true);
        setAutoBooking('joinOpenTableOnClick');

        setIsButtonLoading(false);
      }
    },
    [auth.auth, userProfile, isOpenAuthModal.open, router, setBookSeatState, setIsOpenAuthModal]
  );

  // auto booking after login
  useEffect(() => {
    if (
      auth.auth &&
      userProfile.id != 0 &&
      canNavigateToBooking &&
      autoBooking === 'joinOpenTableOnClick' &&
      autoJoinOpenTable
    ) {
      joinOpenTableOnClick(autoJoinOpenTable);

      setCanNavigateToBooking(false);
      setAutoBooking('');
    }
  }, [
    auth.auth,
    userProfile,
    canNavigateToBooking,
    autoBooking,
    autoJoinOpenTable,
    joinOpenTableOnClick,
  ]);

  const OpenTableCardsList = useMemo(() => {
    const settings = {
      className: 'slider variable-width',
      dots: true,
      arrows: isUpSm,
      autoplay: false,
      infinite: false,
      variableWidth: !isDesktop,
      slidesToShow: isDesktop ? 3 : isUp815px ? 2 : isUp391px ? 1 : 0.75,
      slidesToScroll: isDesktop ? 3 : isUp815px ? 2 : 1,
      beforeChange: (current: number, next: number) => setCurrentIndex(next),
      ...CarouselDots({
        rounded: true,
        sx: { mt: 3 },
      }),
      nextArrow: <NextArrow className="next-arrow-destination" />,
      prevArrow: <PrevArrow className="prev-arrow-destination" />,
    };

    return isDesktop && openTableLists?.length > (isUpMd ? 3 : 1) ? (
      <RootStyle>
        <CarouselArrows
          filled
          // onNext={handleNext}
          // onPrevious={handlePrevious}
          sx={{
            '& .arrow': {
              '&.left': {
                top: 238,
                left: isDesktop ? 8 : 0,
              },
              '&.right': {
                top: 238,
                right: isDesktop ? 8 : 0,
                display:
                  currentIndex < openTableLists?.length - (isDesktop ? 3 : isUp815px ? 2 : 1)
                    ? 'block'
                    : 'none',
              },
            },
          }}
        >
          <Slider {...settings}>
            {openTableLists?.map((item, index) => (
              <Grid container key={index}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  item
                  xs={'auto'}
                  // mr={isUpMd ? 3 : 2}
                  mr={isDesktop ? undefined : isUpMd ? 3 : 2}
                >
                  <OpenTableCard
                    id={
                      item?.type === 'booking'
                        ? `UpcomingOTB_Join_${change_symbol(item?.courseName)}`
                        : `UpcomingOTB_Open_${change_symbol(item?.courseName)}`
                    }
                    openTable={item}
                    onClick={() => {
                      router.push(`${url_course_menu(item?.chef?.name, item?.courseName)}`);
                    }}
                    onButtonClick={() => {
                      setIsButtonLoading(true);
                      setIsIndexButtonLoading(index);

                      joinOpenTableOnClick(item);
                      setAutoJoinOpenTable(item);
                    }}
                    isBooking={item?.type === 'booking'}
                    isButtonLoading={isButtonLoading && isIndexButtonLoading === index}
                  />
                </Grid>
              </Grid>
            ))}
          </Slider>
        </CarouselArrows>
      </RootStyle>
    ) : (
      <Grid
        container
        spacing={isUpMd ? 3 : 2}
        sx={{
          flexWrap: 'nowrap',
          overflow: 'auto',
          scrollbarWidth: 'none',
          '::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        {openTableLists?.map((item, index) => (
          <Grid item xs={'auto'} key={index}>
            <OpenTableCard
              id={
                item?.type === 'booking'
                  ? `UpcomingOTB_Join_${change_symbol(item?.courseName)}`
                  : `UpcomingOTB_Open_${change_symbol(item?.courseName)}`
              }
              openTable={item}
              onClick={() => {
                router.push(`${url_course_menu(item?.chef?.name, item?.courseName)}`);
              }}
              onButtonClick={() => {
                setIsButtonLoading(true);
                setIsIndexButtonLoading(index);

                joinOpenTableOnClick(item);
                setAutoJoinOpenTable(item);
              }}
              isBooking={item?.type === 'booking'}
              isButtonLoading={isButtonLoading && isIndexButtonLoading === index}
            />
          </Grid>
        ))}
      </Grid>
    );
  }, [
    isUpSm,
    isDesktop,
    isUp815px,
    isUp391px,
    setCurrentIndex,
    currentIndex,
    openTableLists,
    isUpMd,
    router,
    joinOpenTableOnClick,
    isButtonLoading,
    isIndexButtonLoading,
  ]);

  return (
    <>
      {openTableLists?.length > 0 && (
        <Box mt={5.625}>
          <Container>
            <Grid
              container
              justifyContent={'space-between'}
              alignItems={isUp720px ? 'center' : 'flex-end'}
            >
              <Grid item xs={10} container alignItems="center">
                <Typography fontSize={isUpSm ? 32 : 24} fontWeight={700}>
                  Upcoming&nbsp;
                </Typography>
                <Typography
                  fontSize={isUpSm ? 32 : 24}
                  fontWeight={700}
                  color={theme.palette.secondary.dark}
                >
                  Chef’s Table&nbsp;
                </Typography>
                <Typography fontSize={isUpSm ? 32 : 24} fontWeight={700}>
                  Experiences
                </Typography>
              </Grid>

              <Grid
                item
                xs={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  id="UpcomingOTB_SeeAll"
                  disableRipple
                  onClick={() => {
                    router.push('/see-all-open-table-courses');
                  }}
                  sx={{
                    alignSelf: 'flex-end',
                    '&:hover': {
                      background: 'transparent',
                      opacity: 0.5,
                    },
                  }}
                >
                  <Typography variant="overline">SEE ALL</Typography>
                </Button>
              </Grid>
            </Grid>

            <Typography variant="body1" mt={isUp720px ? 0 : 2} mb={2}>
              Join the available session to secure the dinner fast
            </Typography>

            {isDesktop && OpenTableCardsList}
          </Container>

          {!isDesktop && <Box pl={isUpMd ? 3 : 2}>{OpenTableCardsList}</Box>}
        </Box>
      )}

      <VerifyPhoneModal
        isOpen={isVerifyPhone}
        onClose={() => {
          setIsVerifyPhone(false);

          // clear auto booking after login
          setCanNavigateToBooking(false);
          setAutoBooking('');
        }}
        callback={() => {
          autoJoinOpenTable && joinOpenTableOnClick(autoJoinOpenTable, true);
        }}
      />
    </>
  );
};

export default OpenTableSection;
