import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { useState, useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { authState, signUpSignInModalState } from '@atoms/auth';
import CreateChefModal from '@components/create-chef-form/CreateChefModal';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { useRouter } from 'next/router';
import { resize_image } from '@utils/image';

const BecomeChefBanner = () => {
  const router = useRouter();
  const setIsOpenSignUpSignInModal = useSetRecoilState(signUpSignInModalState);
  const { auth } = useRecoilValue(authState);
  const [isOpenRegisterChefModal, setIsOpenRegisterChefModal] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const onRegisterBtnClick = () => {
    if (!auth) {
      setIsOpenSignUpSignInModal({
        open: true,
        redirect: '/?chef_register=true',
        mode: 'request-invitation',
      });
      return;
    }
    setIsOpenRegisterChefModal(true);
  };

  useEffect(() => {
    const { chef_register } = router.query;
    if (chef_register) {
      setIsOpenRegisterChefModal(true);
    }
  }, [router.query]);

  return (
    <Box mb={{ xs: 6, sm: 6 }}>
      <Container>
        <Grid
          container
          flexDirection={'column'}
          spacing={3}
          sx={{
            width: '100%',
            borderRadius: 3,
            padding: {
              xs: '42% 32px 25%  32px',
              sm: '8.4% 50% 11.5% 3%',
            },
            position: 'relative',
            backgroundImage: isMobile
              ? `url(${resize_image({
                  url: 'https://images.potioneer.com/images-landing/vitor-monthay-673jcnrm8bM-unsplash_FmSY4X67Q.jpg',
                  width: 567,
                })})`
              : `url(${resize_image({
                  url: 'https://images.potioneer.com/images-landing/become_a_chef_zbAGAoZq8.webp',
                  width: 1152,
                  height: 492,
                })})`,

            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundClip: 'border-box',
            boxSizing: 'border-box',
            margin: 0,
            // margin: '0 0 48px 0',
          }}
        >
          {isMobile ? (
            <>
              <Stack spacing={4}>
                <Typography variant="h3" fontSize={32}>
                  Got ideas to host
                  <br />
                  something cool?
                </Typography>
                <Button
                  id="Banner_joinaschef"
                  onClick={onRegisterBtnClick}
                  sx={{
                    width: '153px',
                    height: '48px',
                    bgcolor: 'white',
                    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
                    borderRadius: 1,
                    padding: '11px 22px',
                    textTransform: 'none',
                  }}
                >
                  Join as Chef
                </Button>
              </Stack>
            </>
          ) : (
            <>
              <Grid item>
                <Typography variant="h3">Have some creative food ideas on your mind?</Typography>
                <Typography variant="h6" mt={2}>
                  Join our community and start building <br /> your course menu today!
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  id="Banner_joinaschef"
                  onClick={onRegisterBtnClick}
                  sx={{
                    bgcolor: 'white',
                    boxShadow: '0px 8px 16px rgba(145, 158, 171, 0.24)',
                    borderRadius: 1,
                    padding: '11px 22px',
                    textTransform: 'none',
                  }}
                >
                  Join as Chef
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <CreateChefModal
        isOpen={isOpenRegisterChefModal}
        onClose={() => setIsOpenRegisterChefModal(false)}
      />
    </Box>
  );
};

export default BecomeChefBanner;
