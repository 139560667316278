import { get, post } from '@utils/fetch';

export interface InvitationResponse {
  url: string;
}

export interface InvitationCountLimitResponse {
  limit: number;
}

export interface InvitationCheckLimitResponse {
  isOverLimit: boolean;
}

export async function get_generate_invitation_url(token: string) {
  return await get<InvitationResponse>(`${process.env.NEXT_PUBLIC_API_URL}/generate-invitation`, {
    Authorization: `Bearer ${token}`,
  });
}

export async function get_invitation_count_limit(token: string) {
  return await get<InvitationCountLimitResponse[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/invitation/count-limit`,
    {
      Authorization: `Bearer ${token}`,
    }
  );
}

export async function get_invitation_check_limit(refId: string) {
  return await get<InvitationCheckLimitResponse>(
    `${process.env.NEXT_PUBLIC_API_URL}/invitation/check-limit?refId=${refId}`
  );
}

export async function post_request_invitation(email: string) {
  return await post<InvitationCheckLimitResponse>(
    `${process.env.NEXT_PUBLIC_API_URL}/request-invitation`,
    {
      email,
    }
  );
}
