import { Box, Typography, Avatar, Card, CardContent, Stack, Divider } from '@mui/material';
import { memo, useEffect, useRef, useState } from 'react';
import moment from 'moment';
import Slider from 'react-slick';
import { styled } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DinersReviewCardProps } from './DinersReviewSection';
import { resize_image } from '@utils/image';
import Image from '@components/Image';
import TextMaxLine from '@components/TextMaxLine';
import { CarouselDots } from '@components/carousel';
import ReviewManage from './ReviewManage';
import { useRecoilValue } from 'recoil';
import { profileState } from '@atoms/profile';
import { useRouter } from 'next/router';
import { url_course_menu } from '@utils/formatUrl';
import Badge from '@mui/material/Badge';
import ReviewLikeButton from '../ReviewLikeButton';
import ChefIconSVG from '../svg/ChefIcon';
import ReportNotify from '@components/review/ReportNotify';

const RootStyle = styled(Box)(({ theme }) => ({
  position: 'relative',
  '& .slick-list': {
    // boxShadow: theme.c,
    // borderRadius: Number(theme.shape.borderRadius) * 2,
  },
  width: '100%',
  margin: 'auto',
}));

type imageSlideType = {
  alt: string;
  image: string;
  liked: boolean;
  count: number;
  onLikeAction: () => void;
};

const DinersReviewCard = (props: DinersReviewCardProps) => {
  const theme = useTheme();
  const router = useRouter();
  // const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down(1000));
  // const isDownMd = useMediaQuery(theme.breakpoints.down(834));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isLabtop = useMediaQuery(theme.breakpoints.down(1200));

  const { courseName, experience, images, reviewAt, user, id, chefName, bookingId } = props.data;
  const reviewDate = moment(reviewAt);
  const profile = useRecoilValue(profileState);

  const [openReportAlert, setOpenReportAlert] = useState<0 | 1 | 2>(0);
  const [isAccDeleted, setSsAccDeleted] = useState<boolean>(false);
  useEffect(() => {
    const deleted: boolean = user?.firstName === '-' && user?.lastName === '-';
    setSsAccDeleted(deleted);
  }, [user]);

  const toCourseMenuPage = (parameter?: string): void => {
    router.push(
      `${url_course_menu(chefName, courseName, parameter ? `?reviewId=${id}&${parameter}` : '')}`
    );
  };

  const ImageSlide = ({ alt, image, liked, count, onLikeAction }: imageSlideType) => {
    return (
      <Box position={'relative'}>
        <ReviewLikeButton
          reviewId={id}
          isLike={liked}
          likeCount={count}
          isFullDisplay={false}
          isAbsolute
          afterLike={(value: number) => onLikeAction()}
        />
        <Image
          onClick={() => toCourseMenuPage('section=review')}
          src={image}
          alt={alt}
          ratio={'1/1'}
        />
      </Box>
    );
  };

  const ImageSlides = ({ images }: { images: string[] }) => {
    const theme = useTheme();
    const carouselRef = useRef<Slider | null>(null);

    const [isLike, setIsLike] = useState<boolean>(false);
    const [likeCount, seLikeCount] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState(
      theme.direction === 'rtl' ? images.length - 1 : 0
    );
    const settings = {
      speed: 800,
      dots: true,
      arrows: false,
      autoplay: false,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      rtl: Boolean(theme.direction === 'rtl'),
      beforeChange: (current: number, next: number) => setCurrentIndex(next),
      ...CarouselDots({
        rounded: true,
        sx: { mt: 1.5 },
      }),
    };

    useEffect(() => {
      setIsLike(props.data?.isLike || false);
      seLikeCount(props.data?.likeCount || 0);
    }, []);

    return (
      <RootStyle id={`see_course_${id}_review`}>
        <Slider ref={carouselRef} {...settings}>
          {images
            .filter((item, i) => i <= 4)
            .map((img, idx) => (
              <ImageSlide
                key={'Become_Venue_' + idx}
                alt={`potioneer-img-${idx}`}
                image={resize_image({
                  url: img,
                  height: 567,
                })}
                liked={isLike}
                count={likeCount}
                onLikeAction={() => {
                  setIsLike(!isLike);
                  seLikeCount(isLike ? likeCount - 1 : likeCount + 1);
                }}
              />
            ))}
        </Slider>
      </RootStyle>
    );
  };

  const formatDateStringToShow = (date: string) => {
    const lowerCaseDate = date.toLowerCase();
    if (lowerCaseDate === '7 days ago') {
      return 'last week';
    } else if (lowerCaseDate === 'a day ago') {
      return 'yesterday';
    } else if (lowerCaseDate === 'a few seconds ago') {
      return 'just now';
    } else if (lowerCaseDate === 'a month ago') {
      return 'last month';
    } else {
      return date
        .replace('seconds', 'sec')
        .replace('minutes', 'mins')
        .replace('hours', 'hr')
        .replace('year', 'yr')
        .replace('months', 'months')
        .replace('days', 'days');
    }
  };

  return (
    <>
      <Card
        id={`CommuReview_Card_${id}`}
        sx={{
          width: '100%',
          backgroundColor: '#FFFFFF',
        }}
      >
        <Stack
          height={'content-fit'}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1.5}
        >
          <Stack
            direction="row"
            px={1.5}
            py={1.5}
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
            onClick={() => {
              props.onChefClick && props.onChefClick();
            }}
            sx={{
              cursor: 'pointer',
            }}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              invisible={!props.data?.user?.isChef}
              badgeContent={<ChefIconSVG />}
            >
              {isAccDeleted ? (
                <Avatar
                  sx={{ width: 27, height: 27 }}
                  alt="default avatar"
                  src={resize_image({
                    url: 'https://images.potioneer.com/images-landing/User-Deleted_bqQWObzG9.png',
                    width: 27,
                    height: 27,
                  })}
                />
              ) : (
                <Avatar
                  sx={{ width: 27, height: 27 }}
                  src={user?.imageProfile?.imageUrl}
                  alt={courseName + '_' + user?.firstName}
                >
                  <Typography variant="body2" color="#212B36" fontWeight={500} fontSize={12}>
                    {user.isChef
                      ? user?.firstName?.split(' ')[0].charAt(0) +
                        user?.firstName?.split(' ')[1]?.charAt(0)
                      : user?.firstName?.charAt(0) + user?.lastName?.charAt(0)}
                  </Typography>
                </Avatar>
              )}
            </Badge>

            <Typography variant="subtitle2" fontSize={12}>
              {isAccDeleted ? '[Deleted]' : ` ${user?.firstName} ${user?.lastName ?? ''}`}
            </Typography>
          </Stack>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              color="#637381"
              variant="subtitle2"
              fontSize={12}
              px={0.5}
              py={1}
              fontWeight={400}
            >
              {formatDateStringToShow(reviewDate.fromNow())}
            </Typography>
            <ReviewManage
              reviewId={id}
              bookingId={bookingId}
              isAuthor={profile.id === user.id}
              shareUrl={`${url_course_menu(
                chefName,
                courseName,
                `?reviewId=${id}&section=review`
              )}`}
              action={{
                fetchReviewData: (reviewId?: number) => {
                  props.fetchReview && props.fetchReview(reviewId);
                },
                setReportAlert: (type: 0 | 1 | 2) => {
                  setOpenReportAlert(type);
                },
              }}
            />
          </Box>
        </Stack>

        <Box
          position="relative"
          sx={{
            cursor: 'pointer',
            width: '100%',
            height: isMobile ? '100%' : 380,
            background: 'white',
          }}
        >
          <ImageSlides images={images.map((image) => image.imageUrl)} />
        </Box>

        <CardContent
          sx={{
            px: 2,
            py: 0,
            mb: -1,
            mt: isMobile ? 2 : isLabtop ? (isTablet ? 3 : 18) : 4,
          }}
        >
          <Box height={45}>
            <TextMaxLine line={2} variant="caption">
              {experience}
            </TextMaxLine>
          </Box>
          <Divider sx={{ mb: 1 }} />
          <Typography
            id={`see_course_${id}_details`}
            variant="caption"
            color="#212B36"
            fontWeight={700}
            sx={{
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={() => toCourseMenuPage()}
          >
            See course details
          </Typography>
        </CardContent>
      </Card>
      <ReportNotify type={openReportAlert} />
    </>
  );
};

export default memo(DinersReviewCard);
