import { get } from '@utils/fetch';
import { ChefsData } from '@services/chef_public';
import { Image } from '@services/upload';
import { CuisineType } from '@services/cuisine_type';

export interface Courses {
  total: number;
  data: CoursesData[];
}

export interface GranMonteCourses {
  courses: CoursesData[];
}

export interface CoursesData {
  id: number;
  isNewCourse: boolean;
  imageSignatureDishes: Image[];
  title: string;
  chef: ChefsData;
  typeOfCuisines: CuisineType[];
  location: Location[];
  timeAvailables: [
    {
      time: string;
    }
  ];
  pricePerGuest: number;
  numberOfBook: number;
}

export interface Location {
  id?: number;
  count: number;
  name: string;
  isParking?: boolean;
}

export const PUBLISHED_OLD = 'PUBLISHED_OLD';
export const PUBLISHED_NEW = 'PUBLISHED_NEW';
export const PRICE_LOW = 'PRICE_LOW';
export const PRICE_HIGH = 'PRICE_HIGHT';
export const POPULARITY = 'POPULARITY';

export async function get_courses(page: number, limit: number, sort: string = PUBLISHED_OLD) {
  return await get<Courses[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/v2/course?page=${page}&limit=${limit}&sort=${sort}`
  );
}

export async function get_courses_next_available(limit = 50) {
  return await get<CoursesData[]>(
    `${process.env.NEXT_PUBLIC_API_URL}/v2/course/next-available?limit=${limit}`
  );
}

export async function get_courses_granmonte() {
  return await get<GranMonteCourses>(
    `${process.env.NEXT_PUBLIC_API_URL}/course/banner?type=GRANMONTE`
  );
}
