import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { FunctionComponent, memo } from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { useRouter } from 'next/router';
import Skeleton from '@mui/material/Skeleton';
import { resize_image } from '@utils/image';
import { change_symbol, url_profile } from '@utils/formatUrl';

interface ChefCardInfo {
  id: number;
  chefImg: string;
  name: string;
  cuisineType: string[];
}

interface ChefCardProps {
  info: ChefCardInfo;
  isLoading?: boolean;
}

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  border: `2px solid ${theme.palette.common.white}`,
}));

const LineClampedTypography = styled(Typography)({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  wordBreak: 'break-all',
  display: '-webkit-box',
  WebkitLineClamp: '1',
  WebkitBoxOrient: 'vertical',
});

const ChefCardWithPortfolioButton: FunctionComponent<ChefCardProps> = (props) => {
  const { id, chefImg, name, cuisineType } = props.info;
  const { isLoading } = props;
  const router = useRouter();

  return (
    <Paper
      sx={{
        borderRadius: '16px',
        width: '192px',
        height: '100%',
        paddingX: 2,
        paddingY: 3,
        boxShadow: ' 0px 16px 32px -4px rgba(145, 158, 171, 0.24)',
      }}
    >
      <Grid container justifyContent="center" alignItems="center" flexDirection="column">
        {isLoading ? (
          <>
            <Skeleton variant="circular" width={64} height={64} />
            <Skeleton variant="rectangular" width={'50%'} height={24} sx={{ mt: 2 }} />
            <Skeleton variant="rectangular" width="100%" height={36} sx={{ mt: 2 }} />
          </>
        ) : (
          <>
            <StyledAvatar
              src={
                chefImg
                  ? resize_image({
                      url: chefImg,
                      width: 60,
                      height: 60,
                    })
                  : ''
              }
              sx={{ width: 64, height: 64 }}
            />
            <LineClampedTypography sx={{ color: '#212B36' }} fontSize={16}>
              {name}
            </LineClampedTypography>
            <LineClampedTypography sx={{ color: '#919EAB' }} fontSize={12}>
              {cuisineType.join(', ')}
            </LineClampedTypography>
            <Button
              id={`ExploreChef_${change_symbol(name)}`}
              sx={{ mt: cuisineType.length > 0 ? 2 : 4.3 }}
              type="button"
              variant="outlined"
              size="medium"
              fullWidth
              onClick={() => router.push(`${url_profile(name)}`)}
            >
              Profile
            </Button>
          </>
        )}
      </Grid>
    </Paper>
  );
};

export default memo(ChefCardWithPortfolioButton);
