import DinersReviewCard from '@components/landing/DinersReviewCard';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { get_review, DinersReviewData } from '@services/review';
import { useRouter } from 'next/router';
import { useMemo, useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import { url_course_menu, url_profile } from '@utils/formatUrl';
import { useRecoilValue } from 'recoil';
import { profileState } from '@atoms/profile';

const LIMIT = 12;

export interface DinersReviewCardProps {
  data: DinersReviewData;
  onClick?: VoidFunction;
  onChefClick?: VoidFunction;
  fetchReview?: any;
}

const DinersReviewSection = () => {
  const [data, setData] = useState<DinersReviewData[]>([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [isUpdate, setIsUpdate] = useState<boolean>();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  // const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const isDownMd = useMediaQuery(theme.breakpoints.down(834));
  // const isDown768px = useMediaQuery(theme.breakpoints.down(768));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const router = useRouter();
  const profile = useRecoilValue(profileState);

  const setReviewData = (data: any) => {
    setData((prev) => [...prev, ...data?.[0]?.data]);
    setTotalPages(Math.ceil(data?.[0]?.total / LIMIT));
  };

  const deleteReview = (id: number) => {
    setData((prev) => prev.filter((item) => item.id !== id));
    setLoading(false);
  };

  const fetchData = () => {
    get_review(page, LIMIT, profile?.id)
      .then((res) => {
        const { code, message, data } = res;
        if (code !== '200') {
          return;
        } else {
          setReviewData(data);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useMemo(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    const scrollGetData = () => {
      if (typeof window !== 'undefined')
        window.addEventListener('scroll', () => {
          if (
            window.pageYOffset >=
            document.documentElement.scrollHeight - (isMobile ? 1500 : 2000)
          ) {
            setIsUpdate(true);
          } else {
            setIsUpdate(false);
          }
        });
    };

    scrollGetData();
  }, [isMobile]);

  useEffect(() => {
    if (isUpdate && totalPages > page) {
      setPage((prev) => prev + 1);

      setTimeout(() => setIsUpdate(false), 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  const CardLoading = () => (
    <Card
      id="CommuReview_Card"
      sx={{
        // width: isDownMd ? (isMobile ? '100%' : 343) : 368,
        width: isDownMd ? '100%' : 368,
        backgroundColor: '#FFFFFF',
        // ml: isDownMd ? 0 : 3,
        // mt: isDownMd ? (isMobile ? 0 : 2) : 3,
        mt: 0,
      }}
    >
      <Stack
        px={1.5}
        py={0.5}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="row"
          // px={1.5}
          py={1.5}
          justifyContent="flex-start"
          alignItems="center"
          spacing={1}
          sx={{
            cursor: 'pointer',
          }}
        >
          <Skeleton
            variant="circular"
            sx={{
              width: 24,
              height: 24,
            }}
          />
          <Skeleton variant="text" width={100} />
        </Stack>
        <Skeleton variant="text" width={100} />
      </Stack>
      <Box
        sx={{
          width: isDownMd ? '100%' : 368,
          height: isDownMd ? 256 : 400,
          background: 'white',
          px: 1.5,
        }}
      >
        <Skeleton
          variant="rectangular"
          sx={{
            borderRadius: 1,
            width: '100%',
            height: '100%',
          }}
        />
      </Box>
      <Stack direction="column" justifyContent="center" alignItems="center" px={1.5} py={1.5}>
        <Skeleton variant="text" width={'100%'} />
        <Skeleton variant="text" width={'100%'} />
      </Stack>
      <Stack direction="row" justifyContent="space-between" alignItems="center" px={1.5} py={1.5}>
        <Skeleton variant="text" width={'40%'} />
      </Stack>
    </Card>
  );

  return (
    <Box>
      <Container>
        {!isMobile && (
          <Grid
            container
            sx={{
              background: '#073938',
              borderRadius: 2,
              p: isMobile ? 2 : isDownMd ? 3 : 4,
              // mx: isMobile ? -1 : isDown768px ? 2 : isDownMd ? 3 : undefined,
              my: isMobile ? 2 : isDownMd ? 3 : 4,
              width: '100%',
            }}
            // spacing={2}
            // alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={5}>
              <Stack
                sx={{
                  height: '100%',
                }}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography variant="h4" sx={{ fontWeight: 700, mb: 2, color: '#FFFFFF' }}>
                  Not sure what to eat? <br /> We got you
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: 400, color: '#FFFFFF' }}>
                  Choose from top rated dishes from other foodies in the community
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <img
                src="https://images.potioneer.com/vector/DinnerReview__JUiwRTzk.png?updatedAt=1694709270190"
                alt="diners-review"
                width="100%"
                height="100%"
              />
            </Grid>
          </Grid>
        )}

        {isMobile && (
          <Box
            sx={{
              background: '#073938',
              borderRadius: 2,
              position: 'relative',
              p: isMobile ? 2 : isDownMd ? 3 : 4,
              // mx: isMobile ? -1 : isDown768px ? 2 : isDownMd ? 3 : undefined,
              my: isMobile ? 2 : isDownMd ? 3 : 4,
              width: '100%',
            }}
          >
            <Box>
              <Stack
                sx={{
                  height: '100%',
                }}
                direction="column"
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography variant="h4" sx={{ fontWeight: 700, mb: 2, color: '#FFFFFF' }}>
                  Not sure what to eat? <br /> We got you
                </Typography>
                <Typography
                  variant="body2"
                  width={'50%'}
                  sx={{ fontWeight: 400, color: '#FFFFFF' }}
                >
                  Choose from top rated dishes from other foodies in the community
                </Typography>
              </Stack>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                bottom: '6.5%',
                width: '90%',
                left: '50%',
                transform: 'translateX(-50%)',
              }}
            >
              <img
                src={
                  isMobile
                    ? 'https://images.potioneer.com/vector/mobile_explore_commu_xo8HWpySx.png?updatedAt=1695619529891'
                    : 'https://images.potioneer.com/vector/DinnerReview__JUiwRTzk.png?updatedAt=1694709270190'
                }
                alt="diners-review"
                width="100%"
                height="100%"
              />
            </Box>
          </Box>
        )}

        <Stack direction="row" maxWidth="100%" alignItems="center" mb={1}>
          <Typography fontSize={isDesktop ? 32 : 24} fontWeight={700} component={'div'}>
            Pick from
            <Typography
              component="span"
              fontSize={isDesktop ? 32 : 24}
              fontWeight={700}
              color="#F15B40"
              ml={isMobile ? 0.7 : 1}
              // display={'inline'}
              style={{ paddingLeft: isDesktop ? 5 : 0 }}
            >
              Community
            </Typography>
          </Typography>
        </Stack>

        <Box
          sx={{
            margin: 'auto',
            display: 'flex',
            justifyContent: 'center',
            // mx: isMobile ? -1 : isDown768px ? 2 : isDownMd ? 3 : undefined,
            width: '100%',
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            sx={{
              width: '100%',
              // ml: isDownMd ? 0 : -3,
              // mt: isDownMd ? -2 : -3,
              // mr: 0,
              // mb: 0,
            }}
          >
            {loading ? (
              <Grid container spacing={isMobile ? 2 : 3}>
                {Array.from(new Array(12)).map((_, idx) => (
                  <Grid key={idx} item xs={12} sm={6} lg={4}>
                    <CardLoading />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid container spacing={2.5}>
                <>
                  {data.map((c, idx) => (
                    <Grid key={idx} item xs={12} sm={6} md={6} lg={4}>
                      <DinersReviewCard
                        data={c}
                        fetchReview={(id: number) => {
                          deleteReview(id);
                        }}
                        onClick={() =>
                          router.push(
                            `${url_course_menu(
                              c?.chefName,
                              c?.courseName,
                              `?reviewId=${c?.id}&section=review`
                            )}`
                          )
                        }
                        onChefClick={() =>
                          router.push(
                            `${url_profile(
                              c.user.isChef ? `${c.user?.firstName}` : `${c.user?.id}`
                            )}`
                          )
                        }
                      />
                    </Grid>
                  ))}

                  {totalPages > page &&
                    [...Array(isMobile ? 1 : isTablet ? 4 : 6)].map((_, idx) => (
                      <Grid key={idx} item xs={12} sm={6} lg={4}>
                        <CardLoading />
                      </Grid>
                    ))}
                </>
              </Grid>
            )}
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

export default DinersReviewSection;
