import Image from '@components/Image';
import { Container, Grid, Typography } from '@mui/material';
import { resend_verify_email } from '@services/auth';
import { FunctionComponent } from 'react';

interface VerifyYourEmailProp {
  email: string;
}

const VerifyYourEmail: FunctionComponent<VerifyYourEmailProp> = ({ email }) => {
  const onResendEmail = () => {
    resend_verify_email(email);
  };

  return (
    <Container maxWidth="xs" sx={{ height: '100%' }}>
      <Grid container justifyContent="center" alignItems="center" height="100%">
        <Grid item py={6}>
          <Grid container justifyContent="center" mb={2}>
            <Grid item>
              <Image src="/icons/ic_warning.svg" alt="warning" sx={{ width: 48, height: 48 }} />
            </Grid>
          </Grid>
          <Typography variant="h3" gutterBottom align="center" mb={2}>
            Please verify your email
          </Typography>
          <Typography variant="body2" gutterBottom align="center" color="#637381" mb={4}>
            We’ve sent a verification link to your inbox
          </Typography>
          <Typography variant="body2" color="#212B36" align="center">
            Don’t receive an email?{' '}
            <Typography
              component="span"
              variant="body2"
              color="#373737"
              style={{ textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }}
              onClick={onResendEmail}
            >
              Resend email
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default VerifyYourEmail;
