import * as yup from 'yup';
import Image from '@components/Image';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Link from 'next/link';
import { DinnerRegisterRequest, register } from '@services/auth';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';

interface Props {
  refId?: string;
  onSignUpSuccess: (email: string) => void;
}

const schema = yup
  .object({
    email: yup.string().required('REQUIRED').email('INVALID'),
    password: yup.string().required('REQUIRED'),
    isAllowDisclaimer: yup
      .bool()
      .required()
      .isTrue('Please agree the terms and conditions before register.'),
    firstname: yup.string().required('REQUIRED'),
    lastname: yup.string().required('REQUIRED'),
  })
  .required();

const SignUp: FunctionComponent<Props> = ({ refId, onSignUpSuccess }) => {
  const [isShowPassword, setIsShowPassword] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<DinnerRegisterRequest>({
    resolver: yupResolver(schema),
    defaultValues: {
      email: '',
      password: '',
      firstname: '',
      lastname: '',
      isAllowDisclaimer: false,
    },
  });

  const onSubmit = (data: DinnerRegisterRequest) => {
    const redirectUrl = window.location.href ?? '';

    register({ ...data, invitationRefId: refId }, redirectUrl).then(({ code, message }) => {
      if (code !== '200') {
        // alert(message);
        return;
      }

      onSignUpSuccess(data.email);
    });
  };

  return (
    <>
      <Grid container justifyContent="center" pt={5}>
        <Grid item>
          <Image
            src="/images/logos/potioneer-logo-mobile.png"
            alt="potioneer logo"
            disabledEffect
            sx={{ width: 48, height: 48 }}
          />
        </Grid>
      </Grid>
      <Box py={3}>
        <Typography variant="h6" align="center">
          Welcome to Potioneer
        </Typography>
        <Box py={3}>
          <Typography variant="body1" align="center">
            Enjoy the world of extraordinary
          </Typography>
          <Typography variant="body1" align="center">
            dining experiences at your fingertips
          </Typography>
        </Box>
      </Box>
      <Stack spacing={2} px={2} component="form" noValidate onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="firstname"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                id="first_name"
                label="First name"
                {...field}
                error={!!errors.firstname?.message}
                // helperText={errors.firstname?.message}
              />
            );
          }}
        />
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                id="last_name"
                label="Last name"
                {...field}
                error={!!errors.lastname?.message}
                // helperText={errors.lastname?.message}
              />
            );
          }}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => {
            return (
              <TextField
                fullWidth
                id="email"
                label="Email"
                autoComplete="email"
                {...field}
                error={!!errors.email?.message}
                // helperText={errors.email?.message}
              />
            );
          }}
        />
        <Controller
          name="password"
          control={control}
          render={({ field }) => {
            return (
              <FormControl sx={{ mb: 1, width: '100%' }} variant="outlined">
                <InputLabel
                  htmlFor="outlined-adornment-password"
                  sx={{
                    color: (theme) =>
                      errors.password?.message ? theme.palette.error.main : 'default',
                  }}
                  color={errors.password?.message ? 'error' : 'primary'}
                >
                  Password
                </InputLabel>
                <OutlinedInput
                  id="password"
                  label="Password"
                  fullWidth
                  type={isShowPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setIsShowPassword((value) => !value)}
                        edge="end"
                      >
                        {isShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...field}
                  error={!!errors.password?.message}
                />
                {/* <FormHelperText error>{errors.password?.message}</FormHelperText> */}
              </FormControl>
            );
          }}
        />
        {(errors.password?.message === 'REQUIRED' ||
          errors.email?.message === 'REQUIRED' ||
          errors.firstname?.message === 'REQUIRED' ||
          errors.lastname?.message === 'REQUIRED' ||
          errors.email?.message === 'INVALID') && (
          <Box sx={{ mt: 2, mb: 3, color: (theme) => theme.palette.error.main, display: 'flex' }}>
            <WarningRoundedIcon sx={{ width: 16, height: 16 }} />
            <Typography ml={0.5} variant="caption">
              {errors.password?.message === 'REQUIRED' ||
              errors.email?.message === 'REQUIRED' ||
              errors.firstname?.message === 'REQUIRED' ||
              errors.lastname?.message === 'REQUIRED'
                ? 'Please enter the required fields.'
                : errors.email?.message === 'INVALID' && 'Email address incorrect.'}
            </Typography>
          </Box>
        )}
        <Controller
          name="isAllowDisclaimer"
          control={control}
          render={({ field }) => {
            return (
              <FormControlLabel
                control={<Checkbox color="primary" {...field} />}
                label={
                  <Typography variant="body2">
                    I agree to the{' '}
                    <Link href="/terms-and-conditions?section=terms-and-conditions">
                      <a target="_blank" style={{ color: '#212B36' }}>
                        Terms of Service Potioneer User Terms
                      </a>
                    </Link>{' '}
                    and{' '}
                    <Link href="/terms-and-conditions?section=privacy-policy">
                      <a target="_blank" style={{ color: '#212B36' }}>
                        Privacy Policy
                      </a>
                    </Link>
                  </Typography>
                }
              />
            );
          }}
        />
        <Box pt={2} pb={3}>
          <Button type="submit" fullWidth variant="contained" size="large">
            Register
          </Button>
        </Box>
      </Stack>
      <Snackbar
        open={!!errors.isAllowDisclaimer?.message}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="error">{errors.isAllowDisclaimer?.message}</Alert>
      </Snackbar>
    </>
  );
};

export default SignUp;
